import { Dispatch } from 'redux'
import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IFeedback } from '../entities/Feedback'
import { sendFeedback } from '../../data/services/feedback'
import { toast } from 'react-toastify'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const FeedbackActions = {
  OPEN_FEEDBACK_MODAL: 'thunk/feedback/openFeedbackModal',
  CLOSE_FEEDBACK_MODAL: 'thunk/feedback/closeFeedbackModal',
  SEND_FEEDBACK: 'thunk/feedback/sendFeedback',
}

export const openFeedbackModalThunk = createAction<string>(
  FeedbackActions.OPEN_FEEDBACK_MODAL,
)

export const closeFeedbackModalThunk = createAction(
  FeedbackActions.CLOSE_FEEDBACK_MODAL,
)

export const sendFeedbackThunk = createAsyncThunk<void, IFeedback, ThunkApi>(
  FeedbackActions.SEND_FEEDBACK,
  async (payload, thunkAPI) => {
    try {
      await sendFeedback({
        ...payload,
        type: thunkAPI.getState().feedback.type,
      })

      toast('Feedback sent successfully', { type: 'success' })
    } catch (error: any) {
      toast('Error sending feedback! Try again, later', { type: 'error' })

      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
