import React, { useState } from 'react'

import {
  TalentCardWrapper,
  ContainerWrapper,
  FlipCardButton,
  GeneralInfo,
  ImageContainer,
  Info,
  MainInfo,
  MoreInfoButton,
  SideContainer,
  StatusBadge,
} from './style'

import { IoArrowRedoSharp } from 'react-icons/io5'
import { AiFillLinkedin, AiFillGithub, AiFillMail } from 'react-icons/ai'
import { theme } from '../../styles/theme'
import { ITalent } from '../../../domain/entities/Talent'
import Avatar from '../Avatar'
import { ROUTES } from '../../Router'

const TalentCard: React.FC<ITalent> = ({
  profile,
  position,
  contact,
  allocated,
  _id,
}) => {
  const [showBack, setShowBack] = useState(false)

  return (
    <TalentCardWrapper>
      <ContainerWrapper showBack={showBack} allocated={allocated}>
        <SideContainer side="front">
          <ImageContainer background="/assets/banner.png">
            <Avatar src={profile.image} alt={profile.name} objectFit="cover" />
          </ImageContainer>
          <Info>
            <MainInfo>
              <h3>{profile.name}</h3>
              <p>{position} </p>
            </MainInfo>
            {/* <SkillsContainer>
              {stack?.slice(0, 6).map((skill: IStack) => (
                <SkillBadge key={skill._id} skill={skill.name} />
              ))}
            </SkillsContainer> */}
          </Info>
          <StatusBadge status={!allocated ? 'success' : 'danger'} />
          <FlipCardButton onClick={() => setShowBack(prev => !prev)}>
            <IoArrowRedoSharp size={20} color={theme.colors.hex.primary.dark} />
          </FlipCardButton>
        </SideContainer>
        <SideContainer side="back">
          <Info>
            <GeneralInfo>
              <h4>Contact</h4>
              <div>
                <a href={contact.github} target="_blank" rel="noreferrer">
                  <AiFillGithub size={20} color={theme.colors.hex.white} />
                </a>
                <a href={contact.linkedin} target="_blank" rel="noreferrer">
                  <AiFillLinkedin size={20} color={theme.colors.hex.white} />
                </a>
                <a
                  href={`mailto:${contact.email}`}
                  target="_blank"
                  rel="noreferrer">
                  <AiFillMail size={20} color={theme.colors.hex.white} />
                </a>
              </div>
            </GeneralInfo>

            <MoreInfoButton>
              <a href={ROUTES.TALENT.replace(':talentId', _id)}>More Info</a>
            </MoreInfoButton>
          </Info>
          <FlipCardButton onClick={() => setShowBack(prev => !prev)}>
            <IoArrowRedoSharp color={theme.colors.hex.white} size={20} />
          </FlipCardButton>
        </SideContainer>
      </ContainerWrapper>
    </TalentCardWrapper>
  )
}

export default TalentCard
