import React from 'react'
import { useFeedback } from '../../hooks/useFeedback'
import { Button } from '../Button'
import { Modal } from '../Modal'
import { Textarea } from '../Textarea'

import * as FeedbackModalStyle from './style'

export const FeedbackModal: React.FC = () => {
  const {
    openFeedbackModal,
    setOpenFeedbackModal,
    feedback,
    changeFeedback,
    actions: { handleSendFeedback },
  } = useFeedback()

  return (
    <Modal
      title="Send a feedback"
      open={openFeedbackModal}
      onClose={() => {
        setOpenFeedbackModal(false)
      }}>
      <FeedbackModalStyle.ModalBody>
        <Textarea
          rows={1}
          onChange={event =>
            changeFeedback({
              ...feedback,
              description: event.target.value,
            })
          }
          value={feedback.description}
        />
        <Button onClick={handleSendFeedback}>Send</Button>
      </FeedbackModalStyle.ModalBody>
    </Modal>
  )
}
