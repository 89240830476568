import styled, { css } from 'styled-components'

type BackdropProps = {
  open: boolean
}

export const Backdrop = styled.div<BackdropProps>`
  ${({ theme, open }) => css`
    background-color: rgba(${theme.colors.rgb.black}, 0.4);

    opacity: ${open ? 1 : 0};
    pointer-events: ${open ? 'all' : 'none'};
    transition: opacity 0.2s linear;

    position: fixed;
    z-index: 4;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    overflow: auto;
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.hex.white};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    overflow-y: auto;
    max-height: calc(100vh - 100px);

    padding: 25px;
    border-radius: 7px;

    min-width: 400px;

    display: flex;
    flex-direction: column;
  `}
`

export const Header = styled.header`
  display: flex;
  align-items: flex-start;

  position: relative;

  & > h3 {
    flex-grow: 1;
    margin-bottom: 40px;
  }
`

export const CloseButton = styled.button`
  cursor: pointer;

  width: 30px;
  height: 30px;

  position: absolute;

  top: 0;
  right: 0;

  background-color: transparent;
  border: none;
`
