import axios from 'axios'
import endpoints from '../../config/endpoints.json'
import {
  IRecruitTalent,
  ITalent,
  ITalentProject,
} from '../../domain/entities/Talent'
import { AppliedFiltersType } from '../../presentation/hooks/useTalent'
import qs from 'qs'
import { IInterest } from '../../domain/entities/Interest'

export function getAllTalents(): Promise<{ items: ITalent[] }> {
  return axios.get(endpoints.talent.get)
}

export function getTalentById(id: string): Promise<ITalent> {
  const url = endpoints.talent.byId.replace('[talentId]', id)
  return axios.get(url)
}

export function getTalentBySkills(
  selectedSkills: AppliedFiltersType[],
): Promise<{ items: ITalent[] }> {
  return axios.get(`${endpoints.talent.get}`, {
    params: {
      skills: selectedSkills,
    },
    paramsSerializer: params =>
      qs.stringify(params, { arrayFormat: 'indices', encode: false }),
  })
}

export function putTalent(talent: ITalent): Promise<void> {
  const url = endpoints.talent.byId.replace('[talentId]', talent._id)
  return axios.put(url, talent)
}

export function deleteTalent({
  talentId,
  companyId,
}: {
  talentId: string
  companyId: string
}): Promise<void> {
  const url = endpoints.talent.byCompanyId.replace('[talentId]', talentId)
  return axios.delete(url, {
    data: {
      companyId,
    },
  })
}

export function createTalent({
  talent,
  companyId,
}: {
  talent: ITalent
  companyId: string
}): Promise<void> {
  return axios.post(endpoints.talent.get, {
    name: talent.profile.name,
    email: talent.contact.email,
    github: talent.contact.github,
    linkedin: talent.contact.github,
    position: talent.position,
    companyId,
  })
}

export function addProjectOnTalent({
  talentId,
  project,
}: {
  talentId: string
  project: ITalentProject
}): Promise<void> {
  const url = endpoints.talent.project.replace('[talentId]', talentId)
  return axios.post(url, project)
}

export function removeProjectFromTalent({
  talentId,
  projectId,
}: {
  talentId: string
  projectId: string
}): Promise<void> {
  const url = endpoints.talent.project.replace('[talentId]', talentId)
  return axios.delete(url, {
    data: {
      projectId,
    },
  })
}

export function addTalent({
  talentInfo,
  skills,
}: {
  talentInfo: IRecruitTalent
  skills: { skill: { _id: string }; level: number }[]
}): Promise<void> {
  return axios.post(endpoints.talent.post, {
    position: talentInfo.position,
    profile: {
      name: talentInfo.name,
      image: talentInfo.image,
    },
    contact: {
      email: talentInfo.email,
      github: talentInfo.github,
      linkedin: talentInfo.linkedin,
      phone: talentInfo.phone,
    },
    skills,
  })
}

export function sendTalentInterest({
  talentId,
  userId,
}: IInterest): Promise<void> {
  return axios.post(endpoints.talent.interest, {
    talent: talentId,
    company: userId,
  })
}
