import {
  createAction,
  createReducer,
  PayloadAction,
  PayloadActionCreator,
  Reducer,
} from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import {
  authFirebaseThunk,
  authSSOThunk,
  authThunk,
  signOutThunk,
  signUpFirebaseThunk,
} from '../thunks/authThunk'
import { IUser } from '../entities/User'
import { postUserThunk, putUserThunk } from '../thunks/userThunk'

export type AuthActionsType =
  | PayloadAction<{ firebaseToken: string; user: IUser }>
  | PayloadAction<null>

export interface AuthState {
  firebaseToken?: string
  user?: IUser
  firstLogin: boolean
  isLoading: boolean
}

export const AUTH_INITIAL_STATE: AuthState = {
  isLoading: false,
  firstLogin: true,
}

export const authSelector = (state: EngageState): AuthState => state.auth

export const changeToken: PayloadActionCreator<string> = createAction(
  'duck/auth/changeToken',
)

export const changeFirstLogin: PayloadActionCreator<boolean> = createAction(
  'duck/auth/changeFirstLogin',
)

function handlePendingThunk(state: AuthState): AuthState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: AuthState): AuthState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleAuthFirebase(
  state: AuthState,
  action: PayloadAction<string>,
): AuthState {
  return {
    ...state,
    firebaseToken: action.payload,
  }
}

function handleAuth(state: AuthState, action: PayloadAction<IUser>): AuthState {
  return {
    ...state,
    user: action.payload,
    firstLogin: !action.payload.company,
    isLoading: false,
  }
}

function handleSignOut(
  state: AuthState,
  action: PayloadAction<void>,
): AuthState {
  return {
    ...state,
    user: undefined,
    firebaseToken: undefined,
    isLoading: false,
  }
}

function handleChangeFirstLogin(
  state: AuthState,
  action: PayloadAction<boolean>,
): AuthState {
  return {
    ...state,
    firstLogin: action.payload,
  }
}

export const authReducer: Reducer<AuthState, AuthActionsType> = createReducer(
  AUTH_INITIAL_STATE,
  {
    [authFirebaseThunk.pending.type]: handlePendingThunk,
    [authFirebaseThunk.rejected.type]: handleRejectedThunk,
    [authFirebaseThunk.fulfilled.type]: handleAuthFirebase,

    [changeToken.type]: handleAuthFirebase,

    [changeFirstLogin.type]: handleChangeFirstLogin,

    [authThunk.pending.type]: handlePendingThunk,
    [authThunk.rejected.type]: handleRejectedThunk,
    [authThunk.fulfilled.type]: handleAuth,

    [signUpFirebaseThunk.pending.type]: handlePendingThunk,
    [signUpFirebaseThunk.rejected.type]: handleRejectedThunk,
    [signUpFirebaseThunk.fulfilled.type]: handleAuthFirebase,

    [authSSOThunk.pending.type]: handlePendingThunk,
    [authSSOThunk.rejected.type]: handleRejectedThunk,
    [authSSOThunk.fulfilled.type]: handleAuthFirebase,

    [signOutThunk.pending.type]: handlePendingThunk,
    [signOutThunk.rejected.type]: handleRejectedThunk,
    [signOutThunk.fulfilled.type]: handleSignOut,

    [postUserThunk.pending.type]: handlePendingThunk,
    [postUserThunk.rejected.type]: handleRejectedThunk,
    [postUserThunk.fulfilled.type]: handleAuth,

    [putUserThunk.pending.type]: handlePendingThunk,
    [putUserThunk.rejected.type]: handleRejectedThunk,
    [putUserThunk.fulfilled.type]: handleAuth,
  },
)
