import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { Persistor } from 'redux-persist/es/types'
import { EngageStore } from '../domain/DomainLayer'
import { Router } from './Router'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from './styles/theme'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { FeedbackModal } from './components/FeedbackModal'

export type MainProps = {
  store: EngageStore
  persistor: Persistor
}

export const Main: React.FC<MainProps> = (props: MainProps) => {
  const { store, persistor } = props
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastContainer />
          <GlobalStyle />
          <Router />
          <FeedbackModal />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  )
}
