import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { ICompany } from '../entities/Company'
import {
  getAllCompanies,
  getCompanyById,
  getCompanyDashboardById,
} from '../../data/services/company'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const CompanyActions = {
  GET_COMPANY_BY_ID: 'thunk/company/getCompanyById',
  GET_COMPANY_DASHBOARD_BY_ID: 'thunk/company/getCompanyDashboardById',
  GET_ALL_COMPANIES: 'thunk/company/getAllCompanies',
}

export const getCompanyByIdThunk = createAsyncThunk<ICompany, string, ThunkApi>(
  CompanyActions.GET_COMPANY_BY_ID,
  async (payload, thunkAPI) => {
    try {
      return await getCompanyById(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const getCompanyDashboardByIdThunk = createAsyncThunk<
  ICompany,
  string,
  ThunkApi
>(CompanyActions.GET_COMPANY_DASHBOARD_BY_ID, async (payload, thunkAPI) => {
  try {
    return await getCompanyDashboardById(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getAllCompaniesThunk = createAsyncThunk<
  ICompany[],
  void,
  ThunkApi
>(CompanyActions.GET_ALL_COMPANIES, async (payload, thunkAPI) => {
  try {
    return await getAllCompanies()
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})
