import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IUser } from '../entities/User'
import { postUser, putUser } from '../../data/services/user'
import { storage } from '../../data/firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { toast } from 'react-toastify'
import { changeFirstLogin } from '../ducks/authReducer'
import { ROUTES } from '../../presentation/Router'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const UserActions = {
  PUT_USER: 'thunk/auth/putUserThunk',
  POST_USER: 'thunk/auth/postUserThunk',
}

export const postUserThunk = createAsyncThunk<IUser, void, ThunkApi>(
  UserActions.POST_USER,
  async (payload, thunkAPI) => {
    try {
      const user = await postUser({ role: 'COMPANY' })

      thunkAPI.dispatch(changeFirstLogin(true))

      return user
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export async function uploadImage(file: File): Promise<string | undefined> {
  try {
    if (file) {
      const storageRef = ref(storage, `/profile/${file.name}`)

      const imageRef = await uploadBytes(storageRef, file)

      return await getDownloadURL(imageRef.ref)
    }
  } catch (error: any) {
    toast('Error uploading the image! Try again, later', { type: 'error' })
    throw Error(error.message)
  }
}

export const putUserThunk = createAsyncThunk<
  IUser,
  { user: IUser; image: File | string },
  ThunkApi
>(UserActions.PUT_USER, async (payload, thunkAPI) => {
  try {
    const imageUrl =
      typeof payload.image === 'string'
        ? payload.user.company.profile.image
        : await uploadImage(payload.image as File)

    const user = await putUser({
      ...payload.user,
      company: {
        ...payload.user.company,
        profile: { ...payload.user.company.profile, image: imageUrl },
      },
    })

    toast('Profile updated successfully', { type: 'success' })

    thunkAPI.dispatch(changeFirstLogin(false))

    window.location.href = ROUTES.FIND_TALENT

    return user
  } catch (error: any) {
    toast('Error updating profile! Try again, later', { type: 'error' })

    return thunkAPI.rejectWithValue(error.message)
  }
})
