/* eslint-disable no-unused-vars */
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute'
// import { Compare } from './pages/Compare'
// import * as Company from './pages/Company'
// import { Talent } from './pages/DetailedTalent'
import { FindTalent } from './pages/Talent/FindTalent'
import { Login } from './pages/Login'
import { Recruit } from './pages/Talent/Recruit'
import { PublicRoute } from './components/PublicRoute'
import { Profile } from './pages/Profile'
import { Talent } from './pages/Talent/DetailedTalent'
// import { Profile } from './pages/Profile'
// import { ProtectedRoute } from './components/ProtectedRoute'

export const ROUTES = {
  ROOT: '/',

  // PUBLIC ROUTES
  LOGIN: '/login',
  PROFILE: '/profile',
  RECRUIT: '/talent/recruit',

  // PRIVATE ROUTES
  DASHBOARD: '/dashboard',
  COMPANY: '/company/:companyId',
  TALENT: '/talent/:talentId',
  FIND_TALENT: '/talent',
  COMPARE: '/talent/compare',
}

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.ROOT} element={<Navigate to={ROUTES.LOGIN} />} />
        <Route path={ROUTES.RECRUIT} element={<Recruit />} />

        <Route path={ROUTES.ROOT} element={<PublicRoute />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
        </Route>
        <Route path={ROUTES.ROOT} element={<PrivateRoute />}>
          <Route path={ROUTES.FIND_TALENT} element={<FindTalent />} />
          <Route path={ROUTES.TALENT} element={<Talent />} />
          <Route path={ROUTES.PROFILE} element={<Profile />} />

          {/* <Route element={<ProtectedRoute acceptRole="ADMIN" />}>
            <Route path={ROUTES.DASHBOARD} element={<Company.Dashboard />} />
            <Route path={ROUTES.COMPANY} element={<Company.Detail />} />
            <Route path={ROUTES.COMPARE} element={<Compare />} />
          </Route> */}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
