import React, { HTMLAttributes } from 'react'

import * as ConcatenatedStyle from './style'

interface ConcatenedButtonsProps extends HTMLAttributes<HTMLElement> {}

const ConcatenedButtons: React.FC<ConcatenedButtonsProps> = ({ children }) => {
  return <ConcatenatedStyle.Wrapper>{children}</ConcatenatedStyle.Wrapper>
}

export default ConcatenedButtons
