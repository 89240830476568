import {
  configureStore,
  EnhancedStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit'
import { ReducersMapObject } from 'redux'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  TalentActionsType,
  talentReducer,
  TalentState,
} from './ducks/talentReducer'
import {
  CompanyActionsType,
  companyReducer,
  CompanyState,
} from './ducks/companyReducer'
import { AuthActionsType, authReducer, AuthState } from './ducks/authReducer'
import {
  SkillActionsType,
  skillReducer,
  SkillState,
} from './ducks/skillReducer'
import {
  ProjectActionsType,
  projectReducer,
  ProjectState,
} from './ducks/projectReducer'
import {
  EventActionsType,
  eventReducer,
  EventState,
} from './ducks/eventReducer'
import {
  FeedbackActionsType,
  feedbackReducer,
  FeedbackState,
} from './ducks/feedbackReducer'

export interface EngageState {
  readonly talent: TalentState
  readonly company: CompanyState
  readonly auth: AuthState
  readonly skill: SkillState
  readonly project: ProjectState
  readonly event: EventState
  readonly feedback: FeedbackState
}

export type EngageActions = TalentActionsType &
  CompanyActionsType &
  SkillActionsType &
  AuthActionsType &
  ProjectActionsType &
  EventActionsType &
  FeedbackActionsType

export type EngageStore = EnhancedStore<EngageState, EngageActions>
const rootReducer: ReducersMapObject<EngageState, EngageActions> = {
  talent: talentReducer,
  company: companyReducer,
  auth: authReducer,
  skill: skillReducer,
  project: projectReducer,
  event: eventReducer,
  feedback: feedbackReducer,
}

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
}

const combinedReducers = combineReducers(rootReducer)

const appReducer = (state: any, action: any) => {
  if (action.type === 'thunk/auth/signOutThunk/fulfilled') {
    state = undefined
  }
  return combinedReducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, appReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
