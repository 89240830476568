import { AuthProvider } from 'firebase/auth'
import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector } from '../../domain/ducks/authReducer'
import {
  authFirebaseThunk,
  authSSOThunk,
  authThunk,
  signOutThunk,
  signUpFirebaseThunk,
} from '../../domain/thunks/authThunk'

import { AppDispatch } from '../../domain/DomainLayer'
import { postUserThunk } from '../../domain/thunks/userThunk'
import { useNavigate } from 'react-router'
import { ROUTES } from '../Router'

type UseAuthProps = {
  email: string
  password: string
  confirmPassword: string
  setEmail: Dispatch<SetStateAction<string>>
  setPassword: Dispatch<SetStateAction<string>>
  setConfirmPassword: Dispatch<SetStateAction<string>>
  loading: boolean
  actions: {
    handleSubmit: (formType: string) => void
    handleSignOut: () => void
    handleSSO: (provider: AuthProvider) => void
  }
}

export const useAuth = (): UseAuthProps => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const { isLoading } = useSelector(authSelector)

  const dispatch = useDispatch<AppDispatch>()

  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (formType: string) => {
      if (formType === 'signIn') {
        await dispatch(authFirebaseThunk({ email, password }))
        await dispatch(authThunk())
      }

      if (formType === 'signUp') {
        await dispatch(signUpFirebaseThunk({ email, password }))
        await dispatch(postUserThunk())
      }

      navigate(ROUTES.FIND_TALENT)
    },
    [email, password, dispatch, navigate],
  )

  const handleSSO = useCallback(
    async (provider: AuthProvider) => {
      await dispatch(authSSOThunk(provider))
      await dispatch(authThunk())
    },
    [dispatch],
  )

  const handleSignOut = useCallback(() => {
    dispatch(signOutThunk())
  }, [dispatch])

  return {
    email,
    password,
    confirmPassword,
    setEmail,
    setPassword,
    setConfirmPassword,
    loading: isLoading,
    actions: { handleSubmit, handleSSO, handleSignOut },
  }
}
