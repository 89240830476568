import { IProject } from '../domain/entities/Project'

type OnlyUniqueType = string | IProject

export const onlyUnique = (
  value: OnlyUniqueType,
  index: number,
  self: Array<OnlyUniqueType>,
) => {
  return self.indexOf(value) === index
}
