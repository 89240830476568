import React, {
  HTMLAttributes,
  ReactElement,
  useCallback,
  useMemo,
} from 'react'

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartData,
  defaults,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
} from 'chart.js'

import { Bar, PolarArea, Radar } from 'react-chartjs-2'
import { theme } from '../styles/theme'
import { onlyUnique } from '../../utils/order'
import { ITalentSkill } from '../../domain/entities/Talent'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  Title,
)

interface PowerChartType extends HTMLAttributes<HTMLElement> {
  talentPower: ITalentSkill[][]
  title?: string
  type: string
  threshold?: number
  color?: string
}

defaults.font.family = "'Montserrat'"

const configChart = {
  label: 'skill level',
  fill: true,
  borderWidth: 1,
}

const RadarChart: React.FC<PowerChartType> = ({
  talentPower = [],
  title,
  threshold = 3,
  color,
  ...rest
}) => {
  const skillLabel = useMemo(() => {
    const chartLabels = talentPower
      .map(data =>
        data
          .filter(skill => skill.level >= threshold && skill)
          .map(skill => skill.skill.name),
      )
      .flat()

    return chartLabels.filter(onlyUnique)
  }, [talentPower, threshold])

  const handleFormatDataset = useCallback(
    (talentSkills: ITalentSkill[], index: number) => {
      const filteredSkills = talentSkills.filter(
        skill => skill.level >= threshold && skill,
      )
      const datasetChart = filteredSkills.map(skill => skill.level)

      return {
        ...configChart,
        backgroundColor: `rgba(${
          color || theme.colors.rgb.compare[index]
        }, .2)`,
        borderColor: `rgba(${color || theme.colors.rgb.compare[index]}, 1)`,
        data: datasetChart,
      }
    },
    [threshold, color],
  )

  return (
    <div {...rest}>
      <Radar
        data={
          {
            labels: skillLabel,
            datasets: talentPower.map((data, index) =>
              handleFormatDataset(data, index),
            ),
          } as ChartData<'radar', (number | null)[], unknown>
        }
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: title,
              align: 'center',
              font: {
                size: 16,
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            r: {
              grid: {
                offset: true,
              },
              beginAtZero: true,
              min: 0,
              max: 5,
              ticks: {
                stepSize: 1,
                backdropColor: 'transparent',
              },
            },
          },
        }}
      />
    </div>
  )
}

const BarChart: React.FC<PowerChartType> = ({
  talentPower = [],
  title,
  threshold = 3,
  color,
  ...rest
}) => {
  const skillLabel = useMemo(() => {
    const chartLabels = talentPower
      .map(data =>
        data
          .filter(skill => skill.level >= threshold && skill)
          .map(skill => skill.skill.name),
      )
      .flat()

    return chartLabels.filter(onlyUnique)
  }, [talentPower, threshold])

  const handleFormatDataset = useCallback(
    (talentSkills: ITalentSkill[], index: number) => {
      const filteredSkills = talentSkills.filter(
        skill => skill.level >= threshold && skill,
      )
      const datasetChart = filteredSkills.map(skill => skill.level)

      return {
        ...configChart,
        backgroundColor: `rgba(${
          color || theme.colors.rgb.compare[index]
        }, .2)`,
        borderColor: `rgba(${color || theme.colors.rgb.compare[index]}, 1)`,
        data: datasetChart,
      }
    },
    [threshold, color],
  )

  return (
    <div {...rest}>
      <Bar
        data={
          {
            labels: skillLabel,
            datasets: talentPower.map((data, index) =>
              handleFormatDataset(data, index),
            ),
          } as ChartData<'bar', (number | null)[], unknown>
        }
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              text: title,
              align: 'center',
              font: {
                size: 16,
              },
            },
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              min: 0,
              max: 5,
              ticks: {
                stepSize: 1,
              },
            },
          },
          indexAxis: 'y',
        }}
      />
    </div>
  )
}

const PolarChart: React.FC<PowerChartType> = ({
  talentPower = [],
  title,
  threshold = 3,
  color,
  ...rest
}) => {
  const skillLabel = useMemo(() => {
    const chartLabels = talentPower
      .map(data =>
        data
          .filter(skill => skill.level >= threshold && skill)
          .map(skill => skill.skill.name),
      )
      .flat()

    return chartLabels.filter(onlyUnique)
  }, [talentPower, threshold])

  const handleFormatDataset = useCallback(
    (talentSkills: ITalentSkill[], index: 0 | 1) => {
      const filteredSkills = talentSkills.filter(
        skill => skill.level >= threshold && skill,
      )
      const datasetChart = filteredSkills.map(skill => skill.level)

      return {
        ...configChart,
        backgroundColor: `rgba(${
          color || theme.colors.rgb.compare[index]
        }, .2)`,
        borderColor: `rgba(${color || theme.colors.rgb.compare[index]}, 1)`,
        data: datasetChart,
      }
    },
    [threshold, color],
  )

  return (
    <div {...rest}>
      <PolarArea
        data={
          {
            labels: skillLabel,
            datasets: talentPower.map((data, index) =>
              handleFormatDataset(data, index as 0 | 1),
            ),
          } as ChartData<'polarArea', (number | null)[], unknown>
        }
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            r: {
              grid: {
                offset: true,
              },
              beginAtZero: true,
              min: 0,
              max: 5,
              ticks: {
                stepSize: 1,
                backdropColor: 'transparent',
              },
              pointLabels: {
                display: true,
                centerPointLabels: true,
                font: {
                  size: 10,
                },
              },
            },
          },
          plugins: {
            title: {
              display: true,
              text: title,
              align: 'center',
              font: {
                size: 16,
              },
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  )
}

const Chart: React.FC<PowerChartType> = props => {
  const chartTypes: { [key: string]: ReactElement } = {
    radar: <RadarChart {...props} />,
    bar: <BarChart {...props} />,
    polar: <PolarChart {...props} />,
  }

  return chartTypes[props.type]
}

export default Chart
