export interface IContact {
  github?: string
  linkedin?: string
  email: string
  phone?: string
}

export const DEFAULT_CONTACT = {
  email: '',
}
