import React from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router'
import { Navigate } from 'react-router-dom'
import { authSelector } from '../../domain/ducks/authReducer'
import { ROUTES } from '../Router'
import NavBar from './NavBar'

export const PrivateRoute: React.FC = () => {
  const { firebaseToken, user, firstLogin } = useSelector(authSelector)

  const location = useLocation()

  if (!firebaseToken) {
    return <Navigate to={ROUTES.LOGIN} />
  }

  if (
    firebaseToken &&
    user &&
    firstLogin &&
    location.pathname !== ROUTES.PROFILE
  ) {
    return <Navigate to={ROUTES.PROFILE} />
  }

  return (
    <>
      <NavBar />
      <main>
        <Outlet />
      </main>
    </>
  )
}
