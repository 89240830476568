import endpoints from '../../config/endpoints.json'
import axios from 'axios'
import { IEvent } from '../../domain/entities/Event'

export function putEvent(event: IEvent): Promise<void> {
  const url = endpoints.event.byId.replace('[eventId]', event._id)

  return axios.put(url, event)
}

export function deleteEvent(eventId: string): Promise<void> {
  const url = endpoints.event.byId.replace('[eventId]', eventId)

  return axios.delete(url)
}

export function createEvent({
  event,
  companyId,
}: {
  event: IEvent
  companyId: string
}): Promise<void> {
  return axios.post(endpoints.event.get, {
    description: event.description,
    type: event.type,
    startAt: event.startAt,
    finishAt: event.finishAt,
    companyId,
  })
}

export function getEventById(eventId: string): Promise<IEvent> {
  const url = endpoints.event.byId.replace('[eventId]', eventId)
  return axios.get(url)
}
