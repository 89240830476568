import { FirebaseOptions, initializeApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { FirebaseStorage, getStorage } from 'firebase/storage'
import { Firestore, getFirestore } from 'firebase/firestore/lite'
import { AppDispatch, EngageStore } from '../../domain/DomainLayer'
import { signOutThunk } from '../../domain/thunks/authThunk'
import { changeToken } from '../../domain/ducks/authReducer'

export let firestore: Firestore
export let auth: Auth
export let storage: FirebaseStorage

const firebaseConfig: FirebaseOptions = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_APP_ID,
}

export async function initializeFirebase(store: EngageStore) {
  const firebaseApp = initializeApp(firebaseConfig)
  firestore = getFirestore(firebaseApp)
  auth = getAuth(firebaseApp)

  await auth.onAuthStateChanged(user => {
    const dispatch: AppDispatch = store.dispatch
    if (!user) {
      dispatch(signOutThunk())
    }
  })

  await auth.onIdTokenChanged(async user => {
    const dispatch: AppDispatch = store.dispatch

    try {
      if (user) {
        const newToken = await user.getIdToken(true)
        dispatch(changeToken(newToken))
      }
    } catch (error) {
      console.error(error)
      dispatch(signOutThunk())
    }
  })

  storage = getStorage(firebaseApp)
}
