import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IUser } from '../entities/User'
import {
  authGoogleSSO,
  signInWithCredentials,
  signOutFirebase,
  signUpWithCredentials,
} from '../../data/firebase/auth'
import { AuthProvider, User } from 'firebase/auth'
import { getLoggedUser } from '../../data/services/auth'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const AuthActions = {
  AUTH_FIREBASE: 'thunk/auth/authFirebaseThunk',
  AUTH: 'thunk/auth/authThunk',
  AUTH_SSO: 'thunk/auth/authSSOThunk',
  SIGN_OUT: 'thunk/auth/signOutThunk',
  CHECK_TOKEN: 'thunk/auth/checkTokenThunk',
}

export const authFirebaseThunk = createAsyncThunk<
  string,
  { email: string; password: string },
  ThunkApi
>(AuthActions.AUTH_FIREBASE, async (payload, thunkAPI) => {
  try {
    const user = await signInWithCredentials(payload.email, payload.password)

    return await user.getIdToken()
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const signUpFirebaseThunk = createAsyncThunk<
  string,
  { email: string; password: string },
  ThunkApi
>(AuthActions.AUTH_FIREBASE, async (payload, thunkAPI) => {
  try {
    const user = await signUpWithCredentials(payload.email, payload.password)

    return await user.getIdToken()
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const authThunk = createAsyncThunk<IUser, void, ThunkApi>(
  AuthActions.AUTH,
  async (payload, thunkAPI) => {
    try {
      return await getLoggedUser()
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const authSSOThunk = createAsyncThunk<string, AuthProvider, ThunkApi>(
  AuthActions.AUTH_SSO,
  async (payload, thunkAPI) => {
    try {
      let user: User = await authGoogleSSO()

      return await user.getIdToken()
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const signOutThunk = createAsyncThunk<void, void, ThunkApi>(
  AuthActions.SIGN_OUT,
  async (payload, thunkAPI) => {
    try {
      await signOutFirebase()
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
