export interface IFeedback {
  user: string
  description: string
  type: FeedbackType
}

export type FeedbackType = 'SKILL' | 'OTHER'

export const DEFAULT_FEEDBACK: IFeedback = {
  user: '',
  description: '',
  type: 'OTHER' as FeedbackType,
}
