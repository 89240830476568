import { ICompanyProfile } from './Company'

export interface IUser {
  _id?: string
  firebaseId: string
  role: string
  company: ICompanyProfile
  talent?: string
  manager?: string
}

export interface IAuth {
  email: string
  password: string
}

export interface IProfile {
  _id?: string
  name?: string
  image?: string
}

export const DEFAULT_PROFILE = {
  name: '',
  image: '',
}

export type ROLE_TYPES = 'COMPANY' | 'ADMIN'
