import { useCallback } from 'react'
import {
  ICompany,
  ICompanyDashboard,
  ICompanyDetailed,
  ICompanyTalent,
  ManageCompanyType,
} from '../../domain/entities/Company'
import { useDispatch, useSelector } from 'react-redux'
import { companySelector } from '../../domain/ducks/companyReducer'
import {
  getAllCompaniesThunk,
  getCompanyByIdThunk,
  getCompanyDashboardByIdThunk,
} from '../../domain/thunks/companyThunk'
import { ITalent } from '../../domain/entities/Talent'
import {
  createTalentThunk,
  deleteTalentThunk,
  putTalentThunk,
} from '../../domain/thunks/talentThunk'
import {
  createProjectThunk,
  deleteProjectThunk,
  putProjectThunk,
} from '../../domain/thunks/projectThunk'
import { IProject } from '../../domain/entities/Project'
import {
  createEventThunk,
  deleteEventThunk,
  putEventThunk,
} from '../../domain/thunks/eventThunk'
import { IEvent } from '../../domain/entities/Event'
import { AppDispatch } from '../../domain/DomainLayer'

type UseCompanyType = {
  company: ICompanyDetailed | undefined
  dashboard: ICompanyDashboard | undefined
  companies: ICompany[]
  loading: boolean
  actions: {
    handleGetCompany: (id: string) => void
    handleGetAllCompanies: () => void
    handleGetDashboard: (id: string) => void
    handleManageTalent: (
      type: ManageCompanyType,
      talent: ITalent | ICompanyTalent,
      companyId: string,
    ) => void
    handleManageProject: (
      type: ManageCompanyType,
      project: IProject,
      companyId: string,
    ) => void
    handleManageEvent: (
      type: ManageCompanyType,
      event: IEvent,
      companyId: string,
    ) => void
  }
}

export const useCompany = (): UseCompanyType => {
  const { allCompanies, selectedCompany, dashboardCompany, isLoading } =
    useSelector(companySelector)

  const dispatch = useDispatch<AppDispatch>()

  const handleGetCompany = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCompanyByIdThunk(companyId))
      } catch (error) {
        return
      }
    },
    [dispatch],
  )

  const handleGetDashboard = useCallback(
    async (companyId: string) => {
      try {
        dispatch(getCompanyDashboardByIdThunk(companyId))
      } catch (error) {
        return
      }
    },
    [dispatch],
  )

  const handleGetAllCompanies = useCallback(async () => {
    try {
      dispatch(getAllCompaniesThunk())
    } catch (error) {
      return
    }
  }, [dispatch])

  const handleManageTalent = useCallback(
    async (
      type: ManageCompanyType,
      talent: ICompanyTalent | ITalent,
      companyId: string,
    ) => {
      if (type === 'PUT') {
        await dispatch(putTalentThunk(talent as ITalent))
      }
      if (type === 'CREATE') {
        await dispatch(
          createTalentThunk({ talent, companyId } as {
            talent: ITalent
            companyId: string
          }),
        )
      }

      if (type === 'DELETE') {
        await dispatch(deleteTalentThunk({ talentId: talent._id, companyId }))
      }

      handleGetCompany(companyId)
    },
    [dispatch, handleGetCompany],
  )

  const handleManageProject = useCallback(
    async (type: ManageCompanyType, project: IProject, companyId: string) => {
      if (type === 'PUT') {
        await dispatch(putProjectThunk(project))
      }
      if (type === 'CREATE') {
        await dispatch(createProjectThunk({ project, companyId }))
      }

      if (type === 'DELETE') {
        await dispatch(deleteProjectThunk(project._id))
      }

      handleGetCompany(companyId)
    },
    [dispatch, handleGetCompany],
  )

  const handleManageEvent = useCallback(
    async (type: ManageCompanyType, event: IEvent, companyId: string) => {
      if (type === 'PUT') {
        await dispatch(putEventThunk(event))
      }
      if (type === 'CREATE') {
        await dispatch(createEventThunk({ event, companyId }))
      }

      if (type === 'DELETE') {
        await dispatch(deleteEventThunk(event._id))
      }

      handleGetCompany(companyId)
    },
    [dispatch, handleGetCompany],
  )

  return {
    company: selectedCompany,
    dashboard: dashboardCompany,
    companies: allCompanies,
    loading: isLoading,
    actions: {
      handleGetCompany,
      handleGetAllCompanies,
      handleGetDashboard,
      handleManageTalent,
      handleManageProject,
      handleManageEvent,
    },
  }
}
