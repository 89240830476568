import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

  width: 100%;
`

export const FormWrapper = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    z-index: 1;
    background-color: ${theme.colors.hex.white};
    width: 100%;
    padding: 40px;
    max-width: 55%;
    border-radius: 7px;

    -webkit-box-shadow: 5px 5px 13px -6px rgba(${theme.colors.rgb.black}, 0.8);
    box-shadow: 5px 5px 13px -6px rgba(${theme.colors.rgb.black}, 0.8);

    @media (max-width: ${theme.bp.sm}) {
      max-width: 70%;
    }

    & > label {
      position: relative;
      width: 110px;
      height: 110px;

      cursor: pointer;

      & > div {
        opacity: 0;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 1;
        }
      }
    }

    & > div {
      display: flex;
      flex-direction: column;

      gap: 20px;

      width: 100%;

      & > label {
        display: flex;
        flex-direction: column;
        gap: 5px;

        white-space: nowrap;

        & > textarea {
          border-color: rgba(${theme.colors.rgb.black}, 0.2);
        }
      }
    }
  `}
`

export const EditOverlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    border-radius: 100%;

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(${theme.colors.rgb.black}, 0.3);
  `}
`
