import { ICompany } from '../../domain/entities/Company'
import endpoints from '../../config/endpoints.json'
import axios from 'axios'

export function getCompanyById(id: string): Promise<ICompany> {
  const url = endpoints.company.getById.replace('[companyId]', id)

  return axios.get(url)
}

export function getCompanyDashboardById(id: string): Promise<ICompany> {
  const url = endpoints.company.getDashboardById.replace('[companyId]', id)

  return axios.get(url)
}

export function getAllCompanies(): Promise<ICompany[]> {
  const url = endpoints.company.getAll

  return axios.get(url)
}
