import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { getAllSkills } from '../../data/services/skill'
import { ISkill } from '../entities/Skill'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const SkillActions = {
  GET_ALL_SKILLS: 'thunk/skill/getAllSkillsThunk',
  GET_DEV_SKILLS: 'thunk/skill/getAllSkillsThunk',
}

export const getAllSkillsThunk = createAsyncThunk<ISkill[], void, ThunkApi>(
  SkillActions.GET_ALL_SKILLS,
  async (payload, thunkAPI) => {
    try {
      return (await getAllSkills()).items
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const getDevSkillsThunk = createAsyncThunk<ISkill[], void, ThunkApi>(
  SkillActions.GET_DEV_SKILLS,
  async (payload, thunkAPI) => {
    try {
      return (await getAllSkills()).items
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
