import axios from 'axios'
import endpoints from '../../config/endpoints.json'
import { IUser } from '../../domain/entities/User'

export function putUser(user: IUser): Promise<IUser> {
  const url = endpoints.user.put.replace(':id', user._id as string)
  return axios.put(url, { company: user.company })
}

export function postUser(user: { role: 'COMPANY' }): Promise<IUser> {
  return axios.post(endpoints.user.post, user)
}
