import React, { HTMLAttributes, ReactNode, useEffect } from 'react'
import { ROUTES } from '../../Router'

import * as NavBarStyle from './style'

import EitreeLogo from '../../assets/logo-eitree.png'
import Avatar from '../Avatar'

import { useSelector } from 'react-redux'
import { authSelector } from '../../../domain/ducks/authReducer'
import { useAuth } from '../../hooks/useAuth'
import { useCompany } from '../../hooks/useCompany'
import { useFeedback } from '../../hooks/useFeedback'

interface NavItemProps extends HTMLAttributes<HTMLLIElement> {
  menu?: ReactNode
  hasSubmenu?: boolean
}

const NavItem: React.FC<NavItemProps> = ({
  children,
  menu = '',
  hasSubmenu = false,
  ...rest
}) => {
  return (
    <NavBarStyle.NavItemContainer {...rest}>
      {!hasSubmenu ? (
        children
      ) : (
        <>
          <span>{menu}</span>
          <NavBarStyle.NavMenu>{children}</NavBarStyle.NavMenu>
        </>
      )}
    </NavBarStyle.NavItemContainer>
  )
}

const NavBar: React.FC = () => {
  const { user } = useSelector(authSelector)

  const { setOpenFeedbackModal } = useFeedback()

  const {
    actions: { handleSignOut },
  } = useAuth()

  const {
    companies,
    actions: { handleGetAllCompanies },
  } = useCompany()

  useEffect(() => {
    if (user?.role === 'ADMIN') {
      handleGetAllCompanies()
    }
  }, [handleGetAllCompanies, user])

  return (
    <NavBarStyle.NavBarWrapper>
      <div>
        <a
          href={user?.role === 'ADMIN' ? ROUTES.DASHBOARD : ROUTES.FIND_TALENT}>
          <img src={EitreeLogo} alt="Eitree EMP" width={160} height={50} />
        </a>

        <NavBarStyle.NavSection>
          {user?.role === 'ADMIN' && (
            <>
              <NavItem>
                <a href={ROUTES.DASHBOARD}>dashboard</a>
              </NavItem>
              <NavItem menu="talents" hasSubmenu>
                <li>
                  <a href={ROUTES.COMPARE}>talent compare</a>
                </li>
                <li>
                  <a href={ROUTES.FIND_TALENT}>find a talent</a>
                </li>
              </NavItem>
              {companies && companies.length > 1 ? (
                <NavItem menu="my companies" hasSubmenu>
                  {companies.map(company => (
                    <li key={company._id}>
                      <a
                        href={ROUTES.COMPANY.replace(
                          ':companyId',
                          company._id,
                        )}>
                        {company.name}
                      </a>
                    </li>
                  ))}
                </NavItem>
              ) : (
                <NavItem>
                  <a
                    href={ROUTES.COMPANY.replace(
                      ':companyId',
                      companies && companies[0] && companies[0]._id,
                    )}>
                    my company
                  </a>
                </NavItem>
              )}
            </>
          )}
          <NavItem
            hasSubmenu
            menu={
              <Avatar
                src={user?.company?.profile?.image}
                alt={user?.company?.profile?.name}
                width={40}
                height={40}
              />
            }>
            <li>
              <a href={ROUTES.PROFILE}>profile</a>
            </li>
            <li>
              <button onClick={() => setOpenFeedbackModal(true)}>
                feedback
              </button>
            </li>
            <li>
              <button onClick={() => handleSignOut()}>sign out</button>
            </li>
          </NavItem>
        </NavBarStyle.NavSection>
      </div>
    </NavBarStyle.NavBarWrapper>
  )
}

export default NavBar
