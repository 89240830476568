import React, { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { ISkill, SkillType } from '../../../../domain/entities/Skill'
import useSkills from '../../../hooks/useSkill'

import ReactSlider from 'react-slider'

import * as RecruitStyle from './style'
import { Button } from '../../../components/Button'
import Avatar from '../../../components/Avatar'
import { GoPencil } from 'react-icons/go'
import { theme } from '../../../styles/theme'
import { useTalents } from '../../../hooks/useTalent'

import { SlArrowUp } from 'react-icons/sl'
import { Loading } from '../../../components/Loading'

const SKILL_TYPE_LABEL = {
  DOMAIN: 'Domain',
  PROGRAMMING_LANGUAGE: 'Programming Language',
  RESOURCE: 'Resource',
  CMS: 'CMS',
  SOFT_SKILL: 'Soft Skills',
}

export const Recruit: React.FC = () => {
  const [recruitSkills, setRecruitSkills] = useState({})
  const [blobImage, setBlobImage] = useState<File>()
  const [talentInfo, setTalentInfo] = useState({
    name: '',
    image: '',
    position: '',
    email: '',
    github: '',
    linkedin: '',
    phone: '',
  })

  const [talentImage, setTalentImage] = useState<string>('')

  const {
    skills,
    actions: { handleGetDevSkills },
  } = useSkills()

  const {
    loading,
    actions: { handleAddTalent },
  } = useTalents()

  const handleSubmit = useCallback(
    (event: any) => {
      event.preventDefault()
      handleAddTalent(talentInfo, blobImage as File, recruitSkills)
    },
    [recruitSkills, handleAddTalent, talentInfo, blobImage],
  )

  useEffect(() => {
    handleGetDevSkills()
  }, [handleGetDevSkills])

  useEffect(() => {
    skills &&
      skills.forEach(skill => {
        setRecruitSkills(prev => ({ ...prev, [skill._id]: 0 }))
      })
  }, [skills])

  useEffect(() => {
    if (blobImage) {
      let reader = new FileReader()
      reader.readAsDataURL(blobImage as Blob)
      reader.onload = event => {
        setTalentImage(event.target?.result as string)
      }
    }
  }, [blobImage])

  const filterSkillByType = useCallback(
    (type: SkillType) => skills.filter(skill => skill.type === type),
    [skills],
  )

  return (
    <RecruitStyle.Wrapper>
      <RecruitStyle.Loading disabled={loading}>
        <Loading type="login" />
      </RecruitStyle.Loading>
      <h2>Dedicated page for talent recruitment</h2>
      <RecruitStyle.TalentWrapper>
        <label>
          <Avatar src={talentImage} />

          <input
            type="file"
            required
            style={{ visibility: 'hidden', maxHeight: 0 }}
            onChange={event => {
              setBlobImage((event.target.files as FileList)[0])
            }}
          />
          <RecruitStyle.EditOverlay>
            <GoPencil size={30} color={theme.colors.hex.gray.normal} />
          </RecruitStyle.EditOverlay>
        </label>

        <RecruitStyle.TalentInfo>
          <input
            type="text"
            required
            placeholder="Full Name"
            value={talentInfo.name}
            style={{ fontSize: 18 }}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, name: event.target.value }))
            }
          />
          <input
            type="text"
            required
            placeholder="Position"
            value={talentInfo.position}
            style={{ fontSize: 18 }}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, position: event.target.value }))
            }
          />
          <input
            type="email"
            required
            placeholder="Email"
            value={talentInfo.email}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, email: event.target.value }))
            }
          />
          <input
            type="text"
            required
            placeholder="Github URL"
            value={talentInfo.github}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, github: event.target.value }))
            }
          />
          <input
            type="text"
            placeholder="Linkedin URL"
            value={talentInfo.linkedin}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, linkedin: event.target.value }))
            }
          />
          <input
            type="text"
            placeholder="Phone"
            value={talentInfo.phone}
            onChange={event =>
              setTalentInfo(prev => ({ ...prev, phone: event.target.value }))
            }
          />
        </RecruitStyle.TalentInfo>
      </RecruitStyle.TalentWrapper>
      <RecruitStyle.TalentDescription>
        <span>
          Fill in according to your skills in the following technologies, being:
        </span>
        <p>0- I don&apos;t know this tech</p>
        <p>1- Basic Proficiency</p>
        <p>2- Learning the skill</p>
        <p>3- Comfortable working with this skill</p>
        <p>4- Expert at this skill</p>
        <p>5- Can teach others this skill</p>
      </RecruitStyle.TalentDescription>

      <form onSubmit={handleSubmit}>
        {Object.keys(SkillType).map(skillType => (
          <SkillWrapper
            header={SKILL_TYPE_LABEL[skillType as SkillType]}
            key={skillType}>
            {filterSkillByType(skillType as SkillType).map(skill => (
              <SkillItem
                key={skill.name}
                skill={skill}
                setSkillLevel={(skill, level) =>
                  setRecruitSkills(prev => ({ ...prev, [skill]: level }))
                }
              />
            ))}
          </SkillWrapper>
        ))}

        <Button type="submit" style={{ maxWidth: 150 }} loading={loading}>
          Submit
        </Button>
      </form>
    </RecruitStyle.Wrapper>
  )
}

type SkillItemProps = {
  skill: ISkill
  setSkillLevel: (skill: string, level: number) => void
}

const SkillItem: React.FC<SkillItemProps> = ({ skill, setSkillLevel }) => {
  return (
    <RecruitStyle.SkillLabel>
      <div>
        <span>{skill.name}</span>
        <ReactSlider
          className="horizontal-slider"
          min={0}
          max={5}
          step={1}
          thumbClassName="thumb"
          trackClassName="track"
          marks={true}
          onChange={event => {
            setSkillLevel(skill._id, event)
          }}
        />
      </div>
    </RecruitStyle.SkillLabel>
  )
}

interface SkillWrapperProps extends HTMLAttributes<HTMLElement> {
  header: string
}

const SkillWrapper: React.FC<SkillWrapperProps> = ({ header, children }) => {
  const [open, setOpen] = useState(false)

  return (
    <RecruitStyle.SkillTypeWrapper open={open}>
      <button type="button" onClick={() => setOpen(prev => !prev)}>
        <p>
          {header}
          <span>
            {' '}
            <SlArrowUp />{' '}
          </span>
        </p>
      </button>
      <RecruitStyle.SkillTypeList open={open}>
        {children}
      </RecruitStyle.SkillTypeList>
    </RecruitStyle.SkillTypeWrapper>
  )
}
