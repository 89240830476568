import axios from 'axios'
import { IAuth, IUser } from '../../domain/entities/User'

import endpoints from '../../config/endpoints.json'

export function getLoggedUser(): Promise<IUser> {
  return axios.get(endpoints.user.getByFirebaseToken)
}

export function createUser(auth: IAuth): Promise<IUser> {
  return axios.post(endpoints.user.post, auth)
}
