import { Dispatch, useCallback, useEffect, useReducer } from 'react'
import {
  IRecruitTalent,
  ITalent,
  ITalentProject,
} from '../../domain/entities/Talent'
import { useDispatch, useSelector } from 'react-redux'
import {
  addProjectOnTalentThunk,
  addTalentThunk,
  getAllTalentsThunk,
  getTalentByIdThunk,
  getTalentsBySkillsThunk,
  removeProjectFromTalentThunk,
  sendTalentInterestThunk,
} from '../../domain/thunks/talentThunk'
import { talentSelector } from '../../domain/ducks/talentReducer'
import { ProjectStatusType } from '../../domain/entities/Project'
import { AppDispatch } from '../../domain/DomainLayer'

export type AppliedFiltersType = {
  id: string
  level: number
}

type UseTalentsType = {
  talents: ITalent[]
  selectedTalent?: ITalent
  loading: boolean
  talentCompany: ITalent
  changeTalentCompany: Dispatch<ITalent>
  talentProject: ITalentProject
  changeTalentProject: Dispatch<ITalentProject>
  actions: {
    handleGetAllTalents: () => void
    handleFilterBySkill: (selectedSkills: AppliedFiltersType[]) => void
    handleGetTalent: (talentId: string) => void
    handleAddProject: (talentId: string, projectId: string) => void
    handleRemoveProject: (talentId: string, projectId: string) => void
    handleAddTalent: (
      talentInfo: IRecruitTalent,
      blobImage: File,
      skills: { [id: string]: number },
    ) => void
    handleSendTalentInterest: (talentId: string) => void
  }
}

export const DEFAULT_TALENT = {
  _id: '',
  profile: {
    name: '',
    image: '',
  },
  contact: {
    github: '',
    linkedin: '',
    email: '',
    phone: '',
  },
  position: '',
  allocated: true,
  skills: [],
}

export const DEFAULT_TALENT_PROJECT = {
  projectId: '',
  allocationPercentage: 0,
  status: 'DOING' as ProjectStatusType,
}

export const useTalents = (): UseTalentsType => {
  const { allTalents, selectedTalent, isLoading } = useSelector(talentSelector)

  const dispatch = useDispatch<AppDispatch>()

  const reducer = (talentCompany: ITalent, info: ITalent): ITalent => ({
    ...talentCompany,
    ...info,
  })
  const [talentCompany, changeTalentCompany] = useReducer(reducer, {
    ...DEFAULT_TALENT,
  })

  const talentProjectReducer = (
    talentProject: ITalentProject,
    info: ITalentProject,
  ): ITalentProject => ({
    ...talentProject,
    ...info,
  })

  const [talentProject, changeTalentProject] = useReducer(
    talentProjectReducer,
    {
      ...DEFAULT_TALENT_PROJECT,
    },
  )

  useEffect(() => {
    if (selectedTalent) {
      changeTalentCompany({ ...selectedTalent })
    }
  }, [selectedTalent])

  const handleGetAllTalents = useCallback(() => {
    dispatch(getAllTalentsThunk())
  }, [dispatch])

  const handleFilterBySkill = useCallback(
    (selectedSkills: AppliedFiltersType[]) => {
      dispatch(getTalentsBySkillsThunk(selectedSkills))
    },
    [dispatch],
  )

  const handleGetTalent = useCallback(
    (talentId: string) => {
      dispatch(getTalentByIdThunk(talentId))
    },
    [dispatch],
  )

  const handleAddProject = useCallback(
    (talentId: string, projectId: string) => {
      dispatch(
        addProjectOnTalentThunk({
          talentId,
          project: { ...talentProject, projectId },
        }),
      )
    },
    [dispatch, talentProject],
  )

  const handleAddTalent = useCallback(
    (
      talentInfo: IRecruitTalent,
      blobImage: File,
      skills: { [id: string]: number },
    ) => {
      const mountedSkills = Object.keys(skills).map(id => ({
        skill: {
          _id: id,
        },
        level: skills[id],
      }))
      const base64Image = talentInfo.image.substring(
        talentInfo.image.indexOf(',') + 1,
      )

      dispatch(
        addTalentThunk({
          talentInfo: { ...talentInfo, image: base64Image },
          skills: mountedSkills,
          blobImage,
        }),
      )
    },
    [dispatch],
  )

  const handleRemoveProject = useCallback(
    async (talentId: string, projectId: string) => {
      await dispatch(
        removeProjectFromTalentThunk({
          talentId,
          projectId,
        }),
      )
      await dispatch(getTalentByIdThunk(talentId))
    },
    [dispatch],
  )

  const handleSendTalentInterest = useCallback(
    (talentId: string) => {
      dispatch(sendTalentInterestThunk(talentId))
    },
    [dispatch],
  )

  return {
    talents: allTalents,
    selectedTalent,
    loading: isLoading,
    talentCompany,
    changeTalentCompany,
    talentProject,
    changeTalentProject,
    actions: {
      handleGetAllTalents,
      handleFilterBySkill,
      handleGetTalent,
      handleAddProject,
      handleRemoveProject,
      handleAddTalent,
      handleSendTalentInterest,
    },
  }
}
