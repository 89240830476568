import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authSelector } from '../../domain/ducks/authReducer'
import { putUserThunk } from '../../domain/thunks/userThunk'
import { toast } from 'react-toastify'
import {
  DEFAULT_COMPANY_PROFILE,
  ICompanyProfile,
} from '../../domain/entities/Company'

export const INTEREST_TYPE_LABEL: { [key: string]: string } = {
  FRONTEND: 'Frontend',
  BACKEND: 'Backend',
  QA: 'QA',
  DESIGN: 'Design',
  DEVOPS: 'Devops',
  MANAGER: 'Manager',
  DATA_SCIENCE: 'Data Science',
}

type UseProfileType = {
  companyProfile: ICompanyProfile
  changeCompanyProfile: React.Dispatch<ICompanyProfile>
  blobImage?: File
  setBlobImage: React.Dispatch<React.SetStateAction<File | undefined>>
  companyInterest: { [key: string]: string }
  setCompanyInterest: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >
  isLoading: boolean
  actions: {
    handlePutUser: (interestTypes: { label: string; value: string }[]) => void
  }
}

export function useProfile(): UseProfileType {
  const { user, isLoading } = useSelector(authSelector)

  const [blobImage, setBlobImage] = useState<File>()

  const [companyInterest, setCompanyInterest] = useState<{
    [key: string]: string
  }>({})

  const reducer = (
    companyProfile: ICompanyProfile,
    info: ICompanyProfile,
  ): ICompanyProfile => ({
    ...companyProfile,
    ...info,
  })
  const [companyProfile, changeCompanyProfile] = useReducer(reducer, {
    ...DEFAULT_COMPANY_PROFILE,
    ...user?.company,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (user && user.company) {
      if (user.company.companyInterest) {
        setCompanyInterest(
          user.company.companyInterest.reduce(
            (obj, item) =>
              Object.assign(obj, { [item.type]: item.description }),
            {},
          ),
        )
      }
    }
  }, [user])

  const handlePutUser = useCallback(
    (interestTypes: { value: string; label: string }[]) => {
      if (user) {
        const keyInterestTypes = interestTypes.map(
          (interest: { value: string; label: string }) => interest.value,
        )

        const mountedCompanyInterest = Object.keys(companyInterest)
          .filter(key => keyInterestTypes.includes(key))
          .map(key => ({
            type: key,
            description: companyInterest[key],
          }))

        if (!blobImage && !companyProfile.profile.image) {
          toast('The Profile image is required', { type: 'error' })
          return
        }

        const mountPayload = {
          user: {
            ...user,
            company: {
              ...companyProfile,
              companyInterest: mountedCompanyInterest,
            },
          },
          image: blobImage
            ? (blobImage as File)
            : (companyProfile.profile.image as string),
        }

        dispatch(putUserThunk(mountPayload))
      }
    },
    [dispatch, user, companyProfile, blobImage, companyInterest],
  )

  return {
    companyProfile,
    changeCompanyProfile,
    blobImage,
    setBlobImage,
    companyInterest,
    setCompanyInterest,
    isLoading,
    actions: {
      handlePutUser,
    },
  }
}
