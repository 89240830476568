import styled from 'styled-components'

export const Wrapper = styled.textarea`
  resize: none;
  padding: 10px;

  min-height: 150px;

  outline: none;
  overflow: hidden;
`
