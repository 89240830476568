const hex = {
  primary: { normal: '#004382', dark: '#1D1839' },
  secondary: '#ff0c63',
  tertiary: '#0DC2E6',
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    light: '#f4f5f7',
    normal: '#ececec',
    dark: '#cccccc',
  },
  green: '#00a02f',
  information: {
    success: '#aed581',
    warning: '#fff176',
    attention: '#ffb74d',
    danger: '#e57373',
    normal: '',
  },
  status: {
    doing: '#11c108',
    awaiting: '#f8e71c',
    done: '#4a90e2',
    canceled: '#ff0c63',
  },
  compare: {
    1: '#004382',
    2: '#ff0c63',
  },
}

const rgb: { [key: string]: string | { [key: string | number]: string } } = {
  primary: { normal: '0, 67, 130', dark: '29, 24, 57' },
  secondary: '255, 12, 99',
  tertiary: '13, 194, 230',
  white: '255, 255, 255',
  black: '0, 0, 0',
  gray: {
    light: '244, 245, 247',
  },
  success: '0, 160, 47',
  status: {
    doing: '17, 193, 8',
    awaiting: '248, 231, 28',
    done: '74, 144, 226',
  },
  compare: {
    0: '0, 67, 130',
    1: '255, 12, 99',
    2: '17, 193, 8',
    3: '255, 183, 77',
    4: '128,0,128',
  },
}

export const colors = {
  hex,
  rgb,
  gradient: {
    primary: `linear-gradient(45deg, ${hex.primary} 50%, #F40F65 100%)`,
  },
}

export default colors
