import styled, { css } from 'styled-components'

export const TalentCardWrapper = styled.div`
  width: 250px;
  min-height: 300px;
  margin: 25px 0;

  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.1s linear;
`

type ContainerWrapperType = {
  showBack: boolean
  allocated: boolean
}

export const ContainerWrapper = styled.div<ContainerWrapperType>`
  ${({ theme, showBack, allocated }) => css`
    transform-style: preserve-3d;
    transition: transform linear 0.5s, opacity linear 0.1s, filter linear 0.1s;
    position: relative;
    width: 100%;
    height: 100%;

    z-index: 100;

    -webkit-box-shadow: 5px 5px 7px -1px rgba(${theme.colors.rgb.black}, 0.3);
    box-shadow: 5px 5px 7px -1px rgba(${theme.colors.rgb.black}, 0.3);

    border-radius: 10px;

    transform: rotateY(${showBack ? '180' : '0'}deg);

    &::after {
      content: "${allocated ? `allocated` : `available`}";
      display: block;
      position: absolute;
      top: 55%;
      right: 0;
      left: 0;
      text-align: center;

      background-color: ${
        allocated
          ? `${theme.colors.hex.secondary}`
          : `${theme.colors.hex.green}`
      };
      color: ${theme.colors.hex.primary.dark};
      font-weight: 500;

      padding: 5px 0;

      transition: opacity 0.3s linear, visibility 0.1s linear;
      opacity: 0;
      visibility: hidden;

      z-index: 3000;
    }

    & > div {
      transition: opacity 0.25s linear, filter 0.2s linear;
    }

    &:hover {
      ${
        !showBack &&
        css`
          & > div {
            opacity: 0.5;
            filter: ${allocated ? 'grayscale()' : 'none'};
          }
        `
      }

      &::after {
        opacity: ${showBack ? 0 : 1};
        visibility: visible;
      }
    `}
`

type SideContainerType = {
  side: 'front' | 'back'
}
export const SideContainer = styled.div<SideContainerType>`
  ${({ theme, side }) => css`
    transform: rotateY(${side === 'front' ? 0 : 180}deg);

    backface-visibility: hidden;
    background-color: ${side === 'front'
      ? theme.colors.hex.white
      : theme.colors.hex.primary.dark};
    background-image: ${side === 'back' && 'url(/assets/banner.png)'};
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    border-radius: 10px;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

type AvatarProps = {
  background: string
}

export const ImageContainer = styled.div<AvatarProps>`
  ${({ theme, background }) => css`
    width: 100%;
    height: 60px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.hex.primary.dark};
    background-image: url(${background});
    background-size: cover;
    background-position: center;

    overflow: visible;

    margin-bottom: 50px;

    & > * {
      top: 50%;
      position: relative;
    }
  `}
`

export const Info = styled.div`
  padding: 10px;
  flex-grow: 1;
  width: calc(100% - 20px);
`

export const MainInfo = styled.div`
  ${({ theme }) => css`
    text-align: center;
    display: flex;
    flex-direction: column;

    gap: 10px;

    margin: 20px 0;

    & > h3,
    & > p {
      margin: 0;
    }

    & > h3 {
      color: ${theme.colors.hex.primary.dark};
    }

    & > p {
      color: rgba(${theme.colors.rgb.secondary}, 0.7);
      font-size: 14px;
    }
  `}
`

export const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 6.5px;
  width: 100%;
`

export const FlipCardButton = styled.button`
  position: absolute;
  right: 5px;
  bottom: 5px;

  border: none;
  background-color: transparent;

  cursor: pointer;
`

export const GeneralInfo = styled.span`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    & > h4,
    & > p {
      margin: 0;
      color: ${theme.colors.hex.white};
    }

    & > div {
      display: flex;
      gap: 10px;
    }
  `}
`

export const MoreInfoButton = styled.span`
  ${({ theme }) => css`
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
    padding: 5px 0;

    background-color: ${theme.colors.hex.white};
    border-radius: 15px;

    * {
      color: ${theme.colors.hex.secondary};
      font-weight: 500;
      text-decoration: none;
    }
  `}
`

type StatusBadgeProp = {
  status: string
}

export const StatusBadge = styled.span<StatusBadgeProp>`
  ${({ theme, status }) => css`
    background-color: ${theme.colors.hex.information[status]};
    width: 10px;
    height: 10px;
    border-radius: 50%;

    position: absolute;
    bottom: 10px;
    left: 10px;
  `}
`
