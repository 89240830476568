import { createGlobalStyle } from 'styled-components'
import { colors } from './colors'
import bp from './breakpoints'

export const theme = {
  colors,
  bp,
}

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${theme.colors.hex.gray.light};
  }

  * {
    font-family: "Montserrat", sans-serif;
  }

  main {
    padding-top: 125px;
    padding-bottom: 30px;
    max-width: 1080px;
    margin: 0 auto;
  }

`
