import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IRecruitTalent, ITalent, ITalentProject } from '../entities/Talent'
import {
  addProjectOnTalent,
  addTalent,
  createTalent,
  deleteTalent,
  getAllTalents,
  getTalentById,
  getTalentBySkills,
  putTalent,
  removeProjectFromTalent,
  sendTalentInterest,
} from '../../data/services/talent'
import { AppliedFiltersType } from '../../presentation/hooks/useTalent'
import { toast } from 'react-toastify'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '../../data/firebase'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const TalentActions = {
  GET_ALL_TALENTS: 'thunk/talent/getAllTalentsThunk',
  GET_TALENTS_BY_SKILLS: 'thunk/talent/getTalentsBySkillsThunk',
  GET_TALENT_BY_ID: 'thunk/talent/getTalentByIdThunk',
  PUT_TALENT: 'thunk/talent/putTalentThunk',
  DELETE_TALENT: 'thunk/talent/deleteTalentThunk',
  CREATE_TALENT: 'thunk/talent/createTalentThunk',
  ADD_PROJECT_ON_TALENT: 'thunk/talent/addProjectOnTalent',
  REMOVE_PROJECT_ON_TALENT: 'thunk/talent/removeProjectFromTalent',
  ADD_TALENT: 'thunk/talent/addTalent',
  SEND_TALENT_INTEREST: 'thunk/talent/sendTalentInterest',
}

export const getAllTalentsThunk = createAsyncThunk<ITalent[], void, ThunkApi>(
  TalentActions.GET_ALL_TALENTS,
  async (payload, thunkAPI) => {
    try {
      return (await getAllTalents()).items
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const getTalentsBySkillsThunk = createAsyncThunk<
  ITalent[],
  AppliedFiltersType[],
  ThunkApi
>(TalentActions.GET_TALENTS_BY_SKILLS, async (payload, thunkAPI) => {
  try {
    return (await getTalentBySkills(payload)).items
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const getTalentByIdThunk = createAsyncThunk<ITalent, string, ThunkApi>(
  TalentActions.GET_TALENT_BY_ID,
  async (payload, thunkAPI) => {
    try {
      return await getTalentById(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const putTalentThunk = createAsyncThunk<void, ITalent, ThunkApi>(
  TalentActions.PUT_TALENT,
  async (payload, thunkAPI) => {
    try {
      await putTalent(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const createTalentThunk = createAsyncThunk<
  void,
  { talent: ITalent; companyId: string },
  ThunkApi
>(TalentActions.CREATE_TALENT, async (payload, thunkAPI) => {
  try {
    await createTalent(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const deleteTalentThunk = createAsyncThunk<
  void,
  { talentId: string; companyId: string },
  ThunkApi
>(TalentActions.DELETE_TALENT, async (payload, thunkAPI) => {
  try {
    await deleteTalent(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const addProjectOnTalentThunk = createAsyncThunk<
  void,
  { talentId: string; project: ITalentProject },
  ThunkApi
>(TalentActions.ADD_PROJECT_ON_TALENT, async (payload, thunkAPI) => {
  try {
    await addProjectOnTalent(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const removeProjectFromTalentThunk = createAsyncThunk<
  void,
  { talentId: string; projectId: string },
  ThunkApi
>(TalentActions.ADD_PROJECT_ON_TALENT, async (payload, thunkAPI) => {
  try {
    await removeProjectFromTalent(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export async function uploadImage(file: File): Promise<string | undefined> {
  try {
    if (file) {
      const storageRef = ref(storage, `/talent/${file.name}`)

      const imageRef = await uploadBytes(storageRef, file)

      return await getDownloadURL(imageRef.ref)
    }
  } catch (error: any) {
    toast('Error uploading the image! Try again, later', { type: 'error' })
    throw Error(error.message)
  }
}

export const addTalentThunk = createAsyncThunk<
  void,
  {
    talentInfo: IRecruitTalent
    skills: { skill: { _id: string }; level: number }[]
    blobImage: File
  },
  ThunkApi
>(TalentActions.ADD_TALENT, async (payload, thunkAPI) => {
  try {
    const talentImage = await uploadImage(payload.blobImage)
    await addTalent({
      skills: payload.skills,
      talentInfo: { ...payload.talentInfo, image: talentImage as string },
    })

    toast('You have successfully registered!', { type: 'success' })
  } catch (error: any) {
    toast('Something went wrong, try again later!', { type: 'error' })

    return thunkAPI.rejectWithValue(error.message)
  }
})

export const sendTalentInterestThunk = createAsyncThunk<void, string, ThunkApi>(
  TalentActions.SEND_TALENT_INTEREST,
  async (payload, thunkAPI) => {
    try {
      await sendTalentInterest({
        talentId: payload,
        userId: thunkAPI.getState().auth.user?.company._id as string,
      })

      toast('Interest sent successfully', { type: 'success' })
    } catch (error: any) {
      toast('Error sending the order! Try again, later', { type: 'error' })

      return thunkAPI.rejectWithValue(error.message)
    }
  },
)
