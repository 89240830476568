import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router'
import { authSelector } from '../../domain/ducks/authReducer'
import { Loading } from './Loading'

import { ROUTES } from '../Router'

export const PublicRoute: React.FC = () => {
  const { firebaseToken, isLoading } = useSelector(authSelector)

  if (firebaseToken && isLoading) {
    return <Loading type="login" />
  }

  return !firebaseToken ? <Outlet /> : <Navigate to={ROUTES.FIND_TALENT} />
}
