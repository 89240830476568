import React, {
  TextareaHTMLAttributes,
  useCallback,
  useRef,
  useState,
} from 'react'

import * as TextareaStyle from './style'

interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const Textarea: React.FC<TextareaProps> = ({ ...rest }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [value, setValue] = useState('')

  const handleChange = useCallback(
    (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val = evt.target?.value
      setValue(val)
    },
    [],
  )

  return (
    <TextareaStyle.Wrapper
      onChange={handleChange}
      ref={textAreaRef}
      value={value}
      rows={1}
      {...rest}
    />
  )
}
