import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    & > * {
      all: unset;
      background-color: ${theme.colors.hex.gray.light};
      border: 1px solid rgba(${theme.colors.rgb.black}, 0.2);
      height: 30px;
      padding: 0 20px;
      color: ${theme.colors.hex.primary.dark};
      font-size: 12px;
      font-weight: 400;

      cursor: pointer;

      &:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }
      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      &[data-selected='true'] {
        background-color: rgba(${theme.colors.rgb.secondary}, 0.2);
        border-color: ${theme.colors.hex.secondary};
      }
    }
  `}
`
