import React, { CSSProperties, HTMLAttributes } from 'react'

import * as ModalStyle from './style'

interface ModalProps extends HTMLAttributes<HTMLElement> {
  open: boolean
  onClose: () => void
  bodyStyle?: CSSProperties
}

export const Modal: React.FC<ModalProps> = ({
  children,
  title,
  open,
  onClose,
  bodyStyle,
}) => {
  return (
    <ModalStyle.Backdrop open={open}>
      <ModalStyle.Wrapper style={bodyStyle}>
        <ModalStyle.Header>
          <h3>{title}</h3>
          <ModalStyle.CloseButton onClick={onClose}>X</ModalStyle.CloseButton>
        </ModalStyle.Header>
        {children}
      </ModalStyle.Wrapper>
    </ModalStyle.Backdrop>
  )
}
