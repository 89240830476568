import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  max-width: 680px;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 30px 40px;

  & > form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 20px;
  }
`
type WrapperProps = {
  disabled?: boolean
}

export const Loading = styled.div<WrapperProps>`
  ${({ disabled }) => css`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(${({ theme }) => theme.colors.rgb.white}, 0.7);

    transition: all 0.2s linear;
    visibility: ${disabled ? 'visible' : 'hidden'};
    opacity: ${disabled ? 1 : 0};

    z-index: 99;
  `}
`

export const SkillLabel = styled.label`
  ${({ theme }) => css`
    display: flex;

    align-items: center;

    gap: 15px;
    width: 50%;

    padding: 15px;
    box-sizing: border-box;

    position: relative;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: 5px;

      width: 100%;
      & > span {
        width: 100%;
      }
    }

    &:nth-child(2n-1) {
      padding-right: 0;
      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: ${theme.colors.hex.gray.normal};
        margin: -15px 0;
      }
    }

    &:last-child {
      padding-right: 15px;
      &:after {
        content: none;
      }
    }

    .horizontal-slider {
      width: 100%;
      height: 50px;
      margin-top: 10px;

      counter-reset: slide -1;

      .track {
        top: 18px;
        height: 4px;

        background-color: ${theme.colors.hex.gray.light};
      }
      .thumb {
        top: 12px;
        width: 5px;
        outline: none;
        height: 5px;
        line-height: 38px;
      }

      .mark {
        top: -10px;
      }

      .mark::after {
        content: counter(slide);
        counter-increment: slide 1;

        margin-left: 5px;
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
    .thumb {
      cursor: pointer;
      position: absolute;
      z-index: 100;
      background: #ffffff;
      border: 5px solid rgb(${theme.colors.rgb.secondary});
      border-radius: 100%;
      display: block;
      box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
      &.active {
        background-color: rgb(${theme.colors.rgb.gray.dark});
      }
    }
    .track {
      position: relative;
      background: rgb(${theme.colors.rgb.white});
      margin: 0 5px;
      &.track-0 {
        background: rgb(${theme.colors.rgb.secondary});
      }
    }
  `}
`

export const TalentWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 20px;

    & > label {
      position: relative;
      width: 110px;
      height: 110px;

      cursor: pointer;

      & > div {
        opacity: 0;
        transition: opacity 0.2s linear;

        &:hover {
          opacity: 1;
        }
      }
    }
  `}
`

export const MarkerImage = styled.span`
  ${({ theme }) => css`
    border: 1px dashed ${theme.colors.hex.gray.dark};

    height: 110px;
    width: 110px;

    display: block;

    border-radius: 100%;
    box-sizing: border-box;
  `}
`

export const EditOverlay = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    border-radius: 100%;

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(${theme.colors.rgb.black}, 0.3);
  `}
`

export const TalentInfo = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    gap: 10px;

    & > input {
      border: none;
      border-bottom: 1px solid transparent;

      transition: border-color 0.2s linear;

      background: transparent;

      outline: none;

      width: 250px;

      padding: 5px 10px;

      &:focus,
      &:hover {
        border-color: rgba(${theme.colors.rgb.black}, 0.3);
      }
    }
  `}
`

export const TalentDescription = styled.div`
  & > p {
    margin: 5px 0;
  }
`

type SkillTypeListProps = {
  open: boolean
}

export const SkillTypeWrapper = styled.section<SkillTypeListProps>`
  ${({ theme, open }) => css`
    display: flex;
    flex-direction: column;

    background-color: ${theme.colors.hex.white};
    padding: 15px;

    border-radius: 10px;

    & > button {
      width: 100%;
      font-size: 20px;

      background-color: transparent;
      border: none;

      display: flex;
      flex-direction: column;
      gap: 15px;

      cursor: pointer;

      & > p {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin: 0;

        & > span {
          transform: rotate(${open ? `0` : `180deg`});
          display: flex;
          align-items: center;
        }
      }
    }
  `}
`

export const SkillTypeList = styled.div<SkillTypeListProps>`
  ${({ open, theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    height: 100%;

    max-height: ${open ? `none` : `0`};

    overflow: hidden;

    margin-top: ${open ? `20px` : `0`};

    &::before {
      content: '';
      height: 1px;
      width: 100%;
      background-color: rgba(${theme.colors.rgb.black}, 0.1);
    }
  `}
`
