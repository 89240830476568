import React, { useEffect, useMemo, useState } from 'react'
import { GoPencil } from 'react-icons/go'
import Select from 'react-select'
import { CompanyInterestType } from '../../../domain/entities/Company'
import Avatar from '../../components/Avatar'
import { Button } from '../../components/Button'
import { Input } from '../../components/Input'
import { Textarea } from '../../components/Textarea'
import { INTEREST_TYPE_LABEL, useProfile } from '../../hooks/useProfile'
import { theme } from '../../styles/theme'

import * as ProfileStyle from './style'
import { ROUTES } from '../../Router'
import { useNavigate } from 'react-router'

export const Profile: React.FC = () => {
  const {
    companyProfile,
    changeCompanyProfile,
    blobImage,
    setBlobImage,
    companyInterest,
    setCompanyInterest,
    isLoading,
    actions: { handlePutUser },
  } = useProfile()

  const [imageUrl, setImageUrl] = useState<string>()

  const navigate = useNavigate()

  const [selectedTypes, setSelectedTypes] = useState<
    { value: string; label: string }[]
  >([])

  useEffect(() => {
    if (blobImage) {
      let reader = new FileReader()
      reader.readAsDataURL(blobImage as Blob)
      reader.onload = event => {
        setImageUrl(event.target?.result as string)
      }
    }
  }, [blobImage])

  const mountedCompanyInterestType = useMemo(
    () =>
      CompanyInterestType.map(type => ({
        value: type,
        label: INTEREST_TYPE_LABEL[type],
      })),
    [],
  )

  useEffect(() => {
    if (companyInterest) {
      setSelectedTypes(
        Object.keys(companyInterest).map(key => ({
          value: key,
          label: INTEREST_TYPE_LABEL[key],
        })),
      )
    }
  }, [companyInterest])

  const style = {
    // @ts-ignore
    control: (base, state) => ({
      ...base,
      '&:focus-within': {
        border: state.isFocused ? 0 : 0,
      },
    }),
  }

  return (
    <ProfileStyle.Container>
      <ProfileStyle.FormWrapper
        onSubmit={event => {
          event.preventDefault()
          handlePutUser(selectedTypes)

          if (!companyProfile.profile) {
            navigate(ROUTES.FIND_TALENT)
          }
        }}>
        <h2>Edit profile</h2>
        <label>
          <Avatar src={imageUrl || companyProfile.profile.image} />

          <input
            type="file"
            style={{ visibility: 'hidden', maxHeight: 0 }}
            onChange={event => {
              setBlobImage((event.target.files as FileList)[0])
            }}
          />
          <ProfileStyle.EditOverlay>
            <GoPencil size={30} color={theme.colors.hex.gray.normal} />
          </ProfileStyle.EditOverlay>
        </label>
        <div>
          <Input
            label="Company Name"
            required
            value={companyProfile.profile.name}
            onChange={event =>
              changeCompanyProfile({
                ...companyProfile,
                profile: {
                  ...companyProfile.profile,
                  name: event?.target.value,
                },
              })
            }
          />
          <Input
            label="Email"
            required
            type="email"
            value={companyProfile.contact.email}
            onChange={event =>
              changeCompanyProfile({
                ...companyProfile,
                contact: {
                  ...companyProfile.contact,
                  email: event.target.value,
                },
              })
            }
          />
          <Input
            label="Phone"
            required
            value={companyProfile.contact.phone}
            onChange={event =>
              changeCompanyProfile({
                ...companyProfile,
                contact: {
                  ...companyProfile.contact,
                  phone: event.target.value,
                },
              })
            }
          />
          <label>
            What you are looking for?
            <Select
              styles={style}
              onChange={interestType => {
                setSelectedTypes(interestType as [])
              }}
              isMulti
              placeholder="Interest"
              // @ts-ignore
              options={mountedCompanyInterestType}
              value={selectedTypes}
            />
          </label>

          {selectedTypes.map(type => {
            return (
              <label key={type.label}>
                <span>
                  For <b>{type.label}</b>, what are you looking for?
                </span>
                <Textarea
                  name={type.value}
                  value={companyInterest[type.value]}
                  onChange={event =>
                    setCompanyInterest(prev => ({
                      ...prev,
                      [type.value]: event.target.value,
                    }))
                  }
                />
              </label>
            )
          })}

          <Button type="submit" loading={isLoading}>
            Save
          </Button>
        </div>
      </ProfileStyle.FormWrapper>
    </ProfileStyle.Container>
  )
}
