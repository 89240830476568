import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import {
  getAllTalentsThunk,
  getTalentByIdThunk,
  getTalentsBySkillsThunk,
  sendTalentInterestThunk,
} from '../thunks/talentThunk'
import { ITalent } from '../entities/Talent'

export type TalentActionsType = PayloadAction<ITalent[]> | PayloadAction<null>

export interface TalentState {
  allTalents: ITalent[]
  selectedTalent?: ITalent
  isLoading: boolean
}

export const TALENT_INITIAL_STATE: TalentState = {
  allTalents: [],
  isLoading: false,
}

export const talentSelector = (state: EngageState): TalentState => state.talent

function handlePendingThunk(state: TalentState): TalentState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: TalentState): TalentState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleFulfilledDefaultThunk(state: TalentState): TalentState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleGetTalents(
  state: TalentState,
  action: PayloadAction<ITalent[]>,
): TalentState {
  return {
    ...state,
    allTalents: action.payload,
    isLoading: false,
  }
}

function handleGetTalentById(
  state: TalentState,
  action: PayloadAction<ITalent>,
): TalentState {
  return {
    ...state,
    selectedTalent: action.payload,
    isLoading: false,
  }
}

export const talentReducer: Reducer<TalentState, TalentActionsType> =
  createReducer(TALENT_INITIAL_STATE, {
    [getAllTalentsThunk.pending.type]: handlePendingThunk,
    [getAllTalentsThunk.rejected.type]: handleRejectedThunk,
    [getAllTalentsThunk.fulfilled.type]: handleGetTalents,

    [getTalentsBySkillsThunk.pending.type]: handlePendingThunk,
    [getTalentsBySkillsThunk.rejected.type]: handleRejectedThunk,
    [getTalentsBySkillsThunk.fulfilled.type]: handleGetTalents,

    [getTalentByIdThunk.pending.type]: handlePendingThunk,
    [getTalentByIdThunk.rejected.type]: handleRejectedThunk,
    [getTalentByIdThunk.fulfilled.type]: handleGetTalentById,

    [sendTalentInterestThunk.pending.type]: handlePendingThunk,
    [sendTalentInterestThunk.rejected.type]: handleRejectedThunk,
    [sendTalentInterestThunk.fulfilled.type]: handleFulfilledDefaultThunk,
  })
