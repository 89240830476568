import { DEFAULT_CONTACT, IContact } from './Contact'
import { IEvent, IEventDashboard } from './Event'
import { IProject, IProjectDashboard } from './Project'
import { DEFAULT_PROFILE, IProfile } from './User'

export interface ICompany {
  _id: string
  name: string
  image: string
  events: IEvent[] | IEventDashboard[]
  users: string[]
  talents: string[] | ICompanyTalent[]
  projects: string[] | IProjectDashboard[] | IProject[]
}

export interface ICompanyDashboard extends ICompany {
  user: string[]
  talents: ICompanyTalent[]
  projects: IProjectDashboard[]
  financeContact?: string
  technicalContact?: string
  events: IEventDashboard[]
}

export interface ICompanyDetailed extends ICompany {
  user: string[]
  talents: ICompanyTalent[]
  projects: IProject[]
  financeContact?: string
  technicalContact?: string
  events: IEvent[]
}

export interface ICompanyTalent {
  _id: string
  name: string
  image: string
  position: string
  talentsOnProjects: IProjectDashboard[]
}

export const CompanyInterestType = [
  'FRONTEND',
  'BACKEND',
  'QA',
  'DESIGN',
  'DEVOPS',
  'MANAGER',
  'DATA_SCIENCE',
]
export interface ICompanyProfile {
  _id?: string
  contact: IContact
  profile: IProfile
  companyInterest?: ICompanyInterest[]
}
export interface ICompanyInterest {
  type: typeof CompanyInterestType[number]
  description: string
}

export type ManageCompanyType = 'PUT' | 'CREATE' | 'DELETE'

export const DEFAULT_COMPANY_PROFILE = {
  contact: DEFAULT_CONTACT,
  profile: DEFAULT_PROFILE,
}
