import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { createBrowserHistory } from 'history'
import { EngageStore } from '../../domain/DomainLayer'
import { signOutThunk } from '../../domain/thunks/authThunk'
import { ROUTES } from '../../presentation/Router'

export const initializeAxios = (store: EngageStore): void => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    async error => {
      try {
        if (error.response.status === 401) {
          // @ts-ignore
          store.dispatch(signOutThunk())
          const history = createBrowserHistory()
          history.replace(ROUTES.LOGIN)
        }

        return Promise.reject(error)
      } catch (error: any) {
        console.error(error)
      }
    },
  )
  axios.interceptors.request.use(
    async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
      const { firebaseToken } = store.getState().auth

      const headers = {
        ...config.headers,
        Authorization: `Bearer ${firebaseToken}`,
      }
      return { ...config, headers }
    },
  )
}
