import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import {
  createEvent,
  deleteEvent,
  getEventById,
  putEvent,
} from '../../data/services/event'
import { IEvent } from '../entities/Event'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const EventActions = {
  GET_EVENT_BY_ID: 'thunk/event/getEventByIdThunk',
  PUT_EVENT: 'thunk/event/putEventThunk',
  DELETE_EVENT: 'thunk/event/deleteEventThunk',
  CREATE_EVENT: 'thunk/event/createEventThunk',
}

export const getEventByIdThunk = createAsyncThunk<IEvent, string, ThunkApi>(
  EventActions.GET_EVENT_BY_ID,
  async (payload, thunkAPI) => {
    try {
      return await getEventById(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const putEventThunk = createAsyncThunk<void, IEvent, ThunkApi>(
  EventActions.PUT_EVENT,
  async (payload, thunkAPI) => {
    try {
      await putEvent(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const deleteEventThunk = createAsyncThunk<void, string, ThunkApi>(
  EventActions.DELETE_EVENT,
  async (payload, thunkAPI) => {
    try {
      await deleteEvent(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const createEventThunk = createAsyncThunk<
  void,
  { event: IEvent; companyId: string },
  ThunkApi
>(EventActions.CREATE_EVENT, async (payload, thunkAPI) => {
  try {
    await createEvent(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})
