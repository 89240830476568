import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IProject } from '../entities/Project'
import { getProjectByIdThunk } from '../thunks/projectThunk'

export type ProjectActionsType = PayloadAction<IProject[]> | PayloadAction<null>

export interface ProjectState {
  selectedProject?: IProject
  isLoading: boolean
}

export const PROJECT_INITIAL_STATE: ProjectState = {
  isLoading: false,
}

export const projectSelector = (state: EngageState): ProjectState =>
  state.project

function handlePendingThunk(state: ProjectState): ProjectState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: ProjectState): ProjectState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleGetProjectById(
  state: ProjectState,
  action: PayloadAction<IProject>,
): ProjectState {
  return {
    ...state,
    selectedProject: action.payload,
    isLoading: false,
  }
}

export const projectReducer: Reducer<ProjectState, ProjectActionsType> =
  createReducer(PROJECT_INITIAL_STATE, {
    [getProjectByIdThunk.pending.type]: handlePendingThunk,
    [getProjectByIdThunk.rejected.type]: handleRejectedThunk,
    [getProjectByIdThunk.fulfilled.type]: handleGetProjectById,
  })
