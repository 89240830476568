import styled, { css } from 'styled-components'

export const NavBarWrapper = styled.section`
  ${({ theme }) => css`
    width: 100vw;
    height: 75px;
    position: fixed;
    background-color: ${theme.colors.hex.white};

    -webkit-box-shadow: 2px 6px 22px -2px rgba(${theme.colors.rgb.black}, 0.2);
    box-shadow: 2px 6px 22px -2px rgba(${theme.colors.rgb.black}, 0.2);

    z-index: 3;

    & > div {
      width: 100%;
      max-width: 1080px;
      margin: 0 auto;
      height: 100%;
      padding: 0 20px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      & > span {
        cursor: pointer;
      }
    }
  `}
`

export const NavSection = styled.nav`
  display: flex;
  height: 100%;
  flex-grow: 1;
  justify-content: flex-end;
`

export const NavItemContainer = styled.li`
  ${({ theme }) => css`
    list-style: none;

    display: flex;
    align-items: center;
    padding: 0 20px;

    position: relative;

    height: 100%;

    cursor: pointer;

    background-color: transparent;

    li {
      list-style: none;
      position: relative;

      &:last-child {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 5px;
          left: 5px;
          height: 1px;
          background-color: ${theme.colors.hex.gray.normal};
        }
      }

      &:first-child {
        &::before {
          content: none;
        }
      }
    }

    a,
    span,
    button {
      text-decoration: none;
      color: ${theme.colors.hex.primary.normal};
      font-weight: 400;
      font-size: 16px;
    }

    button {
      background-color: transparent;
      border: none;
      width: 100%;
      height: 100%;
      text-align: left;
      padding: 0;
      cursor: pointer;
    }

    &:hover {
      background-color: ${theme.colors.hex.gray.light};

      & > ul {
        display: block;
      }
    }
  `}
`

export const NavMenu = styled.ul`
  ${({ theme }) => css`
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: ${theme.colors.hex.white};
    overflow: hidden;

    min-width: 150px;
    display: none;

    padding: 10px 0;

    & > li {
      padding: 20px;
      white-space: nowrap;

      &:hover {
        background-color: ${theme.colors.hex.gray.light};
      }
    }
  `}
`
export const ProfileMenu = styled.div``
