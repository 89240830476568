import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch } from '../../domain/DomainLayer'
import { skillSelector } from '../../domain/ducks/skillReducer'
import { ISkill } from '../../domain/entities/Skill'
import {
  getAllSkillsThunk,
  getDevSkillsThunk,
} from '../../domain/thunks/skillThunk'

type UseSkillsType = {
  skills: ISkill[]
  actions: {
    handleGetAllSkills: () => void
    handleGetDevSkills: () => void
  }
}

export const useSkills = (): UseSkillsType => {
  const { skills } = useSelector(skillSelector)

  const dispatch = useDispatch<AppDispatch>()

  const handleGetAllSkills = useCallback(() => {
    dispatch(getAllSkillsThunk())
  }, [dispatch])

  const handleGetDevSkills = useCallback(() => {
    dispatch(getDevSkillsThunk())
  }, [dispatch])

  return {
    skills,
    actions: { handleGetAllSkills, handleGetDevSkills },
  }
}

export default useSkills
