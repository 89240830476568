import { Dispatch } from 'redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import {
  addComment,
  createProject,
  deleteProject,
  getProjectById,
  putProject,
} from '../../data/services/project'
import { IProject } from '../entities/Project'

export interface ThunkApi {
  dispatch: Dispatch
  state: EngageState
  rejectValue: string
}

export const ProjectActions = {
  GET_PROJECT_BY_ID: 'thunk/project/getProjectByIdThunk',
  PUT_PROJECT: 'thunk/project/putProjectThunk',
  DELETE_PROJECT: 'thunk/project/deleteProjectThunk',
  CREATE_PROJECT: 'thunk/project/createProjectThunk',
  ADD_COMMENT_PROJECT: 'thunk/project/addCommentThunk',
}

export const getProjectByIdThunk = createAsyncThunk<IProject, string, ThunkApi>(
  ProjectActions.GET_PROJECT_BY_ID,
  async (payload, thunkAPI) => {
    try {
      return await getProjectById(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const putProjectThunk = createAsyncThunk<void, IProject, ThunkApi>(
  ProjectActions.PUT_PROJECT,
  async (payload, thunkAPI) => {
    try {
      await putProject(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const deleteProjectThunk = createAsyncThunk<void, string, ThunkApi>(
  ProjectActions.DELETE_PROJECT,
  async (payload, thunkAPI) => {
    try {
      await deleteProject(payload)
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message)
    }
  },
)

export const createProjectThunk = createAsyncThunk<
  void,
  { project: IProject; companyId: string },
  ThunkApi
>(ProjectActions.CREATE_PROJECT, async (payload, thunkAPI) => {
  try {
    await createProject(payload)
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})

export const addCommentThunk = createAsyncThunk<
  void,
  { projectId: string; talentId: string; content: string },
  ThunkApi
>(ProjectActions.ADD_COMMENT_PROJECT, async (payload, thunkAPI) => {
  try {
    const author = thunkAPI.getState().auth.user?.company.profile.name as string

    await addComment({ ...payload, author })
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message)
  }
})
