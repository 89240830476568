import styled, { css } from 'styled-components'

type WrapperProps = {
  isValid: boolean
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, isValid }) => css`
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > label {
      background-color: ${theme.colors.hex.white};
      border: 1px solid
        rgba(
          ${isValid ? theme.colors.rgb.black : theme.colors.rgb.secondary},
          0.2
        );
      border-radius: 3px;

      display: flex;

      & > input {
        padding: 10px 15px;
        font-size: 15px;

        background-color: transparent;
        border: none;
        width: 100%;

        outline: none;
      }
    }
  `}
`

export const ClearButton = styled.button`
  ${({ theme }) => css`
    font-size: 14px;
    width: 40px;
    background-color: transparent;
    border: transparent;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &::before {
      content: '';
      display: block;
      height: 60%;
      width: 1px;
      background-color: rgba(${theme.colors.rgb.black}, 0.3);
      position: absolute;
      left: 0;
    }
  `}
`
