import { Dispatch, useCallback, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { feedbackSelector } from '../../domain/ducks/feedbackReducer'
import {
  DEFAULT_FEEDBACK,
  FeedbackType,
  IFeedback,
} from '../../domain/entities/Feedback'
import {
  closeFeedbackModalThunk,
  openFeedbackModalThunk,
  sendFeedbackThunk,
} from '../../domain/thunks/feedbackThunk'
import { authSelector } from '../../domain/ducks/authReducer'

type UseFeedbackProps = {
  openFeedbackModal: boolean
  setOpenFeedbackModal: (open: boolean, type?: FeedbackType) => void
  feedback: IFeedback
  changeFeedback: Dispatch<IFeedback>
  actions: {
    handleSendFeedback: () => void
  }
}

export function useFeedback(): UseFeedbackProps {
  const { user } = useSelector(authSelector)
  const { openFeedbackModal } = useSelector(feedbackSelector)

  const dispatch = useDispatch()

  const feedbackReducer = (
    feedback: IFeedback,
    info: IFeedback,
  ): IFeedback => ({
    ...feedback,
    ...info,
  })

  const [feedback, changeFeedback] = useReducer(feedbackReducer, {
    ...DEFAULT_FEEDBACK,
  })

  const handleOpenFeedbackModal = useCallback(
    (open: boolean, type: FeedbackType = 'OTHER') => {
      if (open) {
        dispatch(openFeedbackModalThunk(type))
      } else {
        dispatch(closeFeedbackModalThunk())
      }
      changeFeedback(DEFAULT_FEEDBACK)
    },
    [dispatch],
  )

  const handleSendFeedback = useCallback(() => {
    if (user) {
      dispatch(sendFeedbackThunk({ ...feedback, user: user._id as string }))
      dispatch(closeFeedbackModalThunk())
      changeFeedback(DEFAULT_FEEDBACK)
    }
  }, [dispatch, feedback, user])

  return {
    openFeedbackModal,
    setOpenFeedbackModal: handleOpenFeedbackModal,
    feedback,
    changeFeedback,
    actions: {
      handleSendFeedback,
    },
  }
}
