import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth'
import { auth } from '.'

export async function signInWithCredentials(email: string, password: string) {
  return (await signInWithEmailAndPassword(auth, email, password)).user
}

export async function signUpWithCredentials(email: string, password: string) {
  return (await createUserWithEmailAndPassword(auth, email, password)).user
}

export async function authGoogleSSO() {
  const selectedProvider = new GoogleAuthProvider()
  selectedProvider.setCustomParameters({ prompt: 'select_account' })

  return (await signInWithPopup(auth, selectedProvider)).user
}

export async function signOutFirebase() {
  return await signOut(auth)
}
