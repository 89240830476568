import React, { InputHTMLAttributes, useMemo } from 'react'

import * as InputStyle from './style'

interface InputProp extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  showClearButton?: boolean
  onClear?: () => void
  isValid?: boolean
}

export const Input: React.FC<InputProp> = ({
  label,
  showClearButton = false,
  onClear,
  isValid = true,
  ...rest
}) => {
  const hasValue = useMemo(() => rest.value !== '', [rest])

  return (
    <InputStyle.Wrapper isValid={isValid}>
      {label}
      <label>
        <input {...rest} />
        {showClearButton && hasValue && (
          <InputStyle.ClearButton
            onClick={() => {
              if (onClear) {
                onClear()
              }
            }}>
            x
          </InputStyle.ClearButton>
        )}
      </label>
    </InputStyle.Wrapper>
  )
}
