import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;

  .filter-container {
    width: 100%;
  }
`

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;

  margin-bottom: 10px;

  i {
    font-style: normal;
    font-size: 24px;
    font-weight: lighter;
  }

  .select {
    height: 40px;

    &__skill {
      width: 400px;
    }

    &__level {
      width: 100px;
    }
  }
`

export const ApplyFilterButton = styled.button`
  ${({ theme }) => css`
    height: 38px;

    padding: 0 15px;
    border-radius: 5px;
    border: none;
    background-color: ${theme.colors.hex.secondary};
    color: ${theme.colors.hex.white};
    display: flex;
    align-items: center;

    transition: opacity 0.2s linear;

    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  `}
`

export const OtherFilters = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TalentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 26px;
`

export const SearchLabel = styled.label`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 10px;

    color: ${theme.colors.hex.primary.dark};

    font-weight: 600;

    position: relative;

    * {
      font-weight: normal;
    }
  `}
`

export const FilterApplied = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  b {
    margin-right: 10px;
  }
`

export const FilterBadge = styled.span`
  ${({ theme }) => css`
    padding: 0 10px;
    height: 25px;
    background-color: rgba(${theme.colors.rgb.secondary}, 0.2);
    border: 1px rgba(${theme.colors.rgb.secondary}, 1) solid;
    color: ${theme.colors.hex.primary.dark};
    font-size: 12px;
    border-radius: 5px;

    display: flex;
    gap: 5px;
    align-items: center;

    i {
      font-style: normal;
      font-size: 14px;
      font-weight: 300;
      line-height: 10px;
    }
  `}
`

export const RemoveFilterButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background-color: transparent;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0;
    margin-left: 5px;
    height: 100%;

    cursor: pointer;

    ::before {
      content: '';
      display: block;
      height: calc(100% - 10px);
      width: 1px;
      background-color: ${theme.colors.hex.primary.dark};
    }
  `}
`

export const ClearFilterButton = styled.span`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: ${theme.colors.hex.primary.dark};
    font-size: 12px;
    cursor: pointer;
  `}
`

export const FeedbackButton = styled.a`
  position: absolute;
  bottom: -20px;
  right: 0;

  font-size: 12px;
`
