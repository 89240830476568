import React, { ImgHTMLAttributes } from 'react'
import * as AvatarStyle from './style'

import fallbackUserImg from '../../assets/fallback-user-img.png'

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  src?: string
  alt?: string
}

const Avatar: React.FC<AvatarProps> = ({
  width = 104,
  height = 104,
  alt,
  src,
  objectFit,
  ...rest
}) => {
  return (
    <AvatarStyle.Wrapper
      {...rest}
      alt={alt}
      src={src || fallbackUserImg}
      width={width}
      height={height}
      style={{
        borderRadius: '50%',
        objectFit,
      }}
    />
  )
}

export default Avatar
