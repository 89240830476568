import React, { useEffect } from 'react'
import { useTalents } from '../../../hooks/useTalent'
import { AiFillGithub, AiFillLinkedin, AiFillMail } from 'react-icons/ai'
import { theme } from '../../../styles/theme'
import * as DetailedTalentStyle from './style'
import Avatar from '../../../components/Avatar'
import SkeletonLoading from '../../../components/SkeletonLoading'
import Chart from '../../../components/PowerChart'
import { useParams } from 'react-router'
import { Button } from '../../../components/Button'

export const Talent: React.FC = () => {
  const { talentId } = useParams()

  const {
    selectedTalent,
    loading,
    actions: { handleGetTalent, handleSendTalentInterest },
  } = useTalents()

  useEffect(() => {
    if (talentId) {
      handleGetTalent(talentId as string)
    }
  }, [handleGetTalent, talentId])

  return (
    <DetailedTalentStyle.TalentWrapper>
      <DetailedTalentStyle.Info>
        {selectedTalent ? (
          <>
            <Avatar
              src={selectedTalent?.profile.image as string}
              alt={selectedTalent?.profile.name}
              objectFit="cover"
            />
            <DetailedTalentStyle.GeneralInfo>
              <h3>{selectedTalent?.profile.name}</h3>
              <p>{selectedTalent?.position}</p>
            </DetailedTalentStyle.GeneralInfo>
            <DetailedTalentStyle.ContactInfo>
              <DetailedTalentStyle.ContactWrapper>
                <a
                  href={selectedTalent?.contact.github}
                  target="_blank"
                  rel="noreferrer">
                  <AiFillGithub
                    size={30}
                    color={theme.colors.hex.primary.dark}
                  />
                </a>
                <a
                  href={selectedTalent?.contact.linkedin}
                  target="_blank"
                  rel="noreferrer">
                  <AiFillLinkedin
                    size={30}
                    color={theme.colors.hex.primary.dark}
                  />
                </a>
                <a
                  href={`mailto:${selectedTalent?.contact.email}`}
                  target="_blank"
                  rel="noreferrer">
                  <AiFillMail size={30} color={theme.colors.hex.primary.dark} />
                </a>
              </DetailedTalentStyle.ContactWrapper>
              <Button
                loading={loading}
                onClick={() => handleSendTalentInterest(selectedTalent._id)}>
                I want this Talent
              </Button>
            </DetailedTalentStyle.ContactInfo>
          </>
        ) : (
          <>
            <SkeletonLoading
              loadingNumber={1}
              elementProps={{
                style: { width: 75, height: 75, borderRadius: '50%' },
              }}
            />
            <SkeletonLoading
              loadingNumber={2}
              style={{ flexDirection: 'column', gap: 10 }}
              elementProps={{
                style: { width: 200, height: 30, borderRadius: 5 },
              }}
            />

            <SkeletonLoading
              loadingNumber={1}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexGrow: 1,
              }}
              elementProps={{
                style: { width: 100, height: 30, borderRadius: 5 },
              }}
            />
          </>
        )}
      </DetailedTalentStyle.Info>
      <DetailedTalentStyle.ChartSection>
        {selectedTalent && (
          <>
            <Chart
              type="polar"
              title="Soft Skills"
              talentPower={
                [
                  selectedTalent.skills.filter(
                    skill => skill.skill.type === 'SOFT_SKILL',
                  ),
                ] || []
              }
            />
            <Chart
              type="radar"
              title="Domain"
              color={theme.colors.rgb.secondary as string}
              talentPower={
                [
                  selectedTalent.skills.filter(
                    skill => skill.skill.type === 'DOMAIN',
                  ),
                ] || []
              }
            />
            <Chart
              type="polar"
              title="Programming Language"
              talentPower={
                [
                  selectedTalent.skills.filter(
                    skill => skill.skill.type === 'PROGRAMMING_LANGUAGE',
                  ),
                ] || []
              }
            />
            <Chart
              type="radar"
              title="Resources"
              color={theme.colors.rgb.secondary as string}
              talentPower={
                [
                  selectedTalent.skills.filter(
                    skill => skill.skill.type === 'RESOURCE',
                  ),
                ] || []
              }
            />
            <Chart
              type="polar"
              title="CMS"
              threshold={0}
              talentPower={
                [
                  selectedTalent.skills.filter(
                    skill => skill.skill.type === 'CMS',
                  ),
                ] || []
              }
            />
          </>
        )}
      </DetailedTalentStyle.ChartSection>
    </DetailedTalentStyle.TalentWrapper>
  )
}
