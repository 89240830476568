import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TalentCard from '../../../components/TalentCard'
import * as HomeStyle from './style'
import { AppliedFiltersType, useTalents } from '../../../hooks/useTalent'
import { useSkills } from '../../../hooks/useSkill'

import Select from 'react-select'
import SkeletonLoading from '../../../components/SkeletonLoading'
import { FaPlus } from 'react-icons/fa'
import { MdClose } from 'react-icons/md'
import colors from '../../../styles/theme/colors'
import { ITalent } from '../../../../domain/entities/Talent'
import ConcatenedButtons from '../../../components/ConcatenedButtons'
import { SkillType } from '../../../../domain/entities/Skill'
import { useFeedback } from '../../../hooks/useFeedback'

const LEVEL_OPTIONS = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
]

const GROUP_LABEL: { [key: string]: string } = {
  DOMAIN: 'DOMAIN',
  PROGRAMMING_LANGUAGE: 'PROGRAMMING LANGUAGE',
  RESOURCE: 'RESOURCE',
  CMS: 'CMS',
  SOFT_SKILL: 'SOFT SKILL',
}

export const FindTalent: React.FC = () => {
  const {
    talents,
    loading,
    actions: { handleGetAllTalents, handleFilterBySkill },
  } = useTalents()

  const [selectedSkill, setSelectedSkill] = useState<string>('')
  const [selectedLevel, setSelectedLevel] = useState<number | undefined>()

  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersType[]>([])

  const [allocationFilter, setAllocationFilter] = useState<string | undefined>()

  const { setOpenFeedbackModal } = useFeedback()

  const {
    skills,
    actions: { handleGetAllSkills },
  } = useSkills()

  useEffect(() => {
    handleGetAllSkills()
  }, [handleGetAllSkills])

  const alphabetically = useCallback(
    (currentTalent: ITalent, nextTalent: ITalent) =>
      (currentTalent.profile.name as string) <
      (nextTalent.profile.name as string)
        ? -1
        : currentTalent.profile.name === nextTalent.profile.name
        ? 0
        : 1,
    [],
  )

  const availableTalentsAlphabetically = useMemo(
    () => talents.filter(talent => !talent.allocated).sort(alphabetically),
    [talents, alphabetically],
  )

  const unavailableTalentsAlphabetically = useMemo(
    () => talents.filter(talent => talent.allocated).sort(alphabetically),
    [talents, alphabetically],
  )

  const orderedTalents = useMemo(
    () => [
      ...availableTalentsAlphabetically,
      ...unavailableTalentsAlphabetically,
    ],
    [availableTalentsAlphabetically, unavailableTalentsAlphabetically],
  )

  const formattedSkills =
    skills &&
    Object.keys(SkillType).map((key: string) => ({
      label: GROUP_LABEL[key],
      options: skills
        .filter(skill => skill.type === key)
        .map(skill => ({ label: skill.name, value: skill._id })),
    }))

  const handleApplyFilter = useCallback(() => {
    setAppliedFilters(prev => {
      const emptySkillLevel = !selectedSkill || !selectedLevel
      if (emptySkillLevel) {
        return prev
      }

      const addedBeforeIndex = prev.findIndex(
        filteredSkill => filteredSkill.id === selectedSkill,
      )

      if (addedBeforeIndex >= 0) {
        if (prev[addedBeforeIndex].level !== selectedLevel) {
          const addedBefore = prev[addedBeforeIndex]
          const currentFilters = prev
          addedBefore.level = selectedLevel

          currentFilters.splice(addedBeforeIndex, 1, addedBefore)

          return [...currentFilters]
        } else {
          return prev
        }
      }

      return [...prev, { id: selectedSkill, level: selectedLevel as number }]
    })
  }, [selectedSkill, selectedLevel])

  const handleRemoveFilter = useCallback(
    (removedFilter: AppliedFiltersType) => {
      setAppliedFilters(prev => {
        const currentFilters = [...prev]
        currentFilters.splice(prev.indexOf(removedFilter), 1)
        return currentFilters
      })
    },
    [],
  )

  useEffect(() => {
    if (appliedFilters.length > 0) {
      handleFilterBySkill(appliedFilters)
    } else {
      handleGetAllTalents()
    }
  }, [appliedFilters, handleFilterBySkill, handleGetAllTalents])

  const byAllocation = useCallback(
    (dev: ITalent) => {
      if (allocationFilter === 'allocated') {
        return dev.allocated
      }
      if (allocationFilter === 'available') {
        return !dev.allocated
      }
      if (!allocationFilter) {
        return dev
      }
    },
    [allocationFilter],
  )

  return (
    <HomeStyle.Wrapper>
      <HomeStyle.FilterContainer>
        <HomeStyle.SearchLabel>
          <Select
            className="select select__skill"
            onChange={skill => {
              // @ts-ignore
              setSelectedSkill(skill?.value as string)
            }}
            placeholder="Skill"
            options={formattedSkills}
          />

          <HomeStyle.FeedbackButton
            href="/"
            onClick={event => {
              event.preventDefault()
              setOpenFeedbackModal(true, 'SKILL')
            }}>
            Didn&#39;t find the skill you wanted?
          </HomeStyle.FeedbackButton>
        </HomeStyle.SearchLabel>
        <i>≥</i>
        <HomeStyle.SearchLabel>
          <Select
            className="select select__level"
            onChange={level => {
              setSelectedLevel(level?.value)
            }}
            placeholder="Level"
            options={LEVEL_OPTIONS}
          />
        </HomeStyle.SearchLabel>
        <HomeStyle.ApplyFilterButton
          onClick={handleApplyFilter}
          disabled={!(selectedSkill && selectedLevel)}>
          <FaPlus color={colors.hex.white} size={16} />
        </HomeStyle.ApplyFilterButton>
      </HomeStyle.FilterContainer>
      <HomeStyle.FilterApplied>
        <b>Looking for:</b>
        {appliedFilters.length > 0 ? (
          appliedFilters.map(filter => (
            <HomeStyle.FilterBadge key={filter.id}>
              {skills.find(skill => skill._id === filter.id)?.name} <i>≥</i>{' '}
              {filter.level}
              <HomeStyle.RemoveFilterButton
                onClick={() => handleRemoveFilter(filter)}>
                <MdClose color={colors.hex.primary.dark} size={16} />
              </HomeStyle.RemoveFilterButton>
            </HomeStyle.FilterBadge>
          ))
        ) : (
          <HomeStyle.FilterBadge>All</HomeStyle.FilterBadge>
        )}
        {appliedFilters.length > 0 && (
          <HomeStyle.ClearFilterButton onClick={() => setAppliedFilters([])}>
            Clear all
          </HomeStyle.ClearFilterButton>
        )}
      </HomeStyle.FilterApplied>
      <HomeStyle.OtherFilters>
        <ConcatenedButtons>
          <button
            data-selected={!allocationFilter}
            onClick={() => setAllocationFilter(undefined)}>
            All
          </button>
          <button
            data-selected={allocationFilter === 'allocated'}
            onClick={() => setAllocationFilter('allocated')}>
            Allocated
          </button>
          <button
            data-selected={allocationFilter === 'available'}
            onClick={() => setAllocationFilter('available')}>
            Available
          </button>
        </ConcatenedButtons>
      </HomeStyle.OtherFilters>
      <HomeStyle.TalentsContainer>
        {loading ? (
          <SkeletonLoading
            style={{ gap: 20 }}
            elementProps={{
              style: { width: 250, height: 300, borderRadius: 5 },
            }}
          />
        ) : orderedTalents.length > 0 ? (
          orderedTalents
            .filter(byAllocation)
            .map(talent => <TalentCard key={talent._id} {...talent} />)
        ) : (
          <h2>Reach out and we can find a dev with the desired skill!</h2>
        )}
      </HomeStyle.TalentsContainer>
    </HomeStyle.Wrapper>
  )
}
