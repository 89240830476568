import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { FeedbackType } from '../entities/Feedback'
import {
  closeFeedbackModalThunk,
  openFeedbackModalThunk,
} from '../thunks/feedbackThunk'

export type FeedbackActionsType = PayloadAction<null>

export interface FeedbackState {
  openFeedbackModal: boolean
  type: FeedbackType
  isLoading: boolean
}

export const FEEDBACK_INITIAL_STATE: FeedbackState = {
  openFeedbackModal: false,
  type: 'OTHER',
  isLoading: false,
}

export const feedbackSelector = (state: EngageState): FeedbackState =>
  state.feedback

function handleOpenFeedbackModal(
  state: FeedbackState,
  action: PayloadAction<FeedbackType>,
): FeedbackState {
  return {
    ...state,
    openFeedbackModal: true,
    type: action.payload,
  }
}

function handleCloseFeedbackModal(
  state: FeedbackState,
  action: PayloadAction<void>,
): FeedbackState {
  return {
    ...state,
    openFeedbackModal: false,
  }
}

export const feedbackReducer: Reducer<FeedbackState, FeedbackActionsType> =
  createReducer(FEEDBACK_INITIAL_STATE, {
    [openFeedbackModalThunk.type]: handleOpenFeedbackModal,
    [closeFeedbackModalThunk.type]: handleCloseFeedbackModal,
  })
