import endpoints from '../../config/endpoints.json'
import axios from 'axios'
import { IProject } from '../../domain/entities/Project'

export function putProject(project: IProject): Promise<void> {
  const url = endpoints.project.byId.replace('[projectId]', project._id)

  return axios.put(url, project)
}

export function deleteProject(projectId: string): Promise<void> {
  const url = endpoints.project.byId.replace('[projectId]', projectId)

  return axios.delete(url)
}

export function createProject({
  project,
  companyId,
}: {
  project: IProject
  companyId: string
}): Promise<void> {
  return axios.post(endpoints.project.get, {
    name: project.name,
    jobCode: project.jobCode,
    projectManager: project.projectManager,
    technicalManager: project.technicalManager,
    companyId,
  })
}

export function getProjectById(projectId: string): Promise<IProject> {
  const url = endpoints.project.byId.replace('[projectId]', projectId)
  return axios.get(url)
}

export function addComment({
  projectId,
  talentId,
  content,
  author,
}: {
  projectId: string
  talentId: string
  content: string
  author: string
}): Promise<IProject> {
  const url = endpoints.talent.commentByProject.replace('[talentId]', talentId)
  return axios.post(url, { projectId, author, content })
}
