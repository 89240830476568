import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { IEvent } from '../entities/Event'
import { getEventByIdThunk } from '../thunks/eventThunk'

export type EventActionsType = PayloadAction<IEvent> | PayloadAction<null>

export interface EventState {
  selectedEvent?: IEvent
  isLoading: boolean
}

export const EVENT_INITIAL_STATE: EventState = {
  isLoading: false,
}

export const eventSelector = (state: EngageState): EventState => state.event

function handlePendingThunk(state: EventState): EventState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: EventState): EventState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleGetEventById(
  state: EventState,
  action: PayloadAction<IEvent>,
): EventState {
  return {
    ...state,
    selectedEvent: action.payload,
    isLoading: false,
  }
}

export const eventReducer: Reducer<EventState, EventActionsType> =
  createReducer(EVENT_INITIAL_STATE, {
    [getEventByIdThunk.pending.type]: handlePendingThunk,
    [getEventByIdThunk.rejected.type]: handleRejectedThunk,
    [getEventByIdThunk.fulfilled.type]: handleGetEventById,
  })
