/* eslint-disable no-unused-vars */
export enum SkillType {
  DOMAIN = 'DOMAIN',
  PROGRAMMING_LANGUAGE = 'PROGRAMMING_LANGUAGE',
  RESOURCE = 'RESOURCE',
  CMS = 'CMS',
  SOFT_SKILL = 'SOFT_SKILL',
}

export interface ISkill {
  createdAt?: string
  deletedAt?: string
  _id: string
  name: string
  type: SkillType
  updatedAt?: string
}

export interface IStack {
  _id: string
  name: string
}
