import React, { ReactElement } from 'react'

import logoIcon from '../../assets/logo-icon.png'

import * as LoadingStyle from './style'

const PulseLoading: React.FC = () => {
  const dots = Array(3).fill('')

  return (
    <LoadingStyle.PulseWrapper>
      {dots.map((dot, index) => (
        <span key={index} />
      ))}
    </LoadingStyle.PulseWrapper>
  )
}

const LoginLoading: React.FC = () => {
  return (
    <LoadingStyle.FullScreenWrapper>
      <img src={logoIcon} alt="Eitree" />
    </LoadingStyle.FullScreenWrapper>
  )
}

type LoadingProps = {
  type?: 'pulse' | 'login'
}

export const Loading: React.FC<LoadingProps> = ({ type = 'pulse' }) => {
  const loadingType: { [key: string]: ReactElement } = {
    pulse: <PulseLoading />,
    login: <LoginLoading />,
  }

  return loadingType[type]
}
