import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import { getAllSkillsThunk, getDevSkillsThunk } from '../thunks/skillThunk'
import { ISkill } from '../entities/Skill'

export type SkillActionsType = PayloadAction<ISkill[]> | PayloadAction<null>

export interface SkillState {
  skills: ISkill[]
  isLoading: boolean
}

export const SKILL_INITIAL_STATE: SkillState = {
  skills: [],
  isLoading: false,
}

export const skillSelector = (state: EngageState): SkillState => state.skill

function handlePendingThunk(state: SkillState): SkillState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: SkillState): SkillState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleGetSkills(
  state: SkillState,
  action: PayloadAction<ISkill[]>,
): SkillState {
  return {
    ...state,
    skills: action.payload,
    isLoading: false,
  }
}

function handleGetDevSkills(
  state: SkillState,
  action: PayloadAction<ISkill[]>,
): SkillState {
  return {
    ...state,
    skills: action.payload,
    isLoading: false,
  }
}

export const skillReducer: Reducer<SkillState, SkillActionsType> =
  createReducer(SKILL_INITIAL_STATE, {
    [getAllSkillsThunk.pending.type]: handlePendingThunk,
    [getAllSkillsThunk.rejected.type]: handleRejectedThunk,
    [getAllSkillsThunk.fulfilled.type]: handleGetSkills,
    [getDevSkillsThunk.pending.type]: handlePendingThunk,
    [getDevSkillsThunk.rejected.type]: handleRejectedThunk,
    [getDevSkillsThunk.fulfilled.type]: handleGetDevSkills,
  })
