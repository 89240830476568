const bp = {
  xs: '300px',
  sm: '600px',
  md: '900px',
  lg: '1200px',
  xl: '1500px',
  xxl: '1800px',
}

export default bp
