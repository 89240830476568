import { createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit'
import { EngageState } from '../DomainLayer'
import {
  getAllCompaniesThunk,
  getCompanyByIdThunk,
  getCompanyDashboardByIdThunk,
} from '../thunks/companyThunk'
import {
  ICompany,
  ICompanyDashboard,
  ICompanyDetailed,
} from '../entities/Company'

export type CompanyActionsType = PayloadAction<ICompany[]> | PayloadAction<null>

export interface CompanyState {
  allCompanies: ICompany[]
  selectedCompany?: ICompanyDetailed
  dashboardCompany?: ICompanyDashboard
  isLoading: boolean
}

export const COMPANY_INITIAL_STATE: CompanyState = {
  allCompanies: [],
  isLoading: false,
}

export const companySelector = (state: EngageState): CompanyState =>
  state.company

function handlePendingThunk(state: CompanyState): CompanyState {
  return {
    ...state,
    isLoading: true,
  }
}

function handleRejectedThunk(state: CompanyState): CompanyState {
  return {
    ...state,
    isLoading: false,
  }
}

function handleGetCompanyById(
  state: CompanyState,
  action: PayloadAction<ICompanyDetailed>,
): CompanyState {
  return {
    ...state,
    selectedCompany: action.payload,
    isLoading: false,
  }
}

function handleGetCompanyDashboardById(
  state: CompanyState,
  action: PayloadAction<ICompanyDashboard>,
): CompanyState {
  return {
    ...state,
    dashboardCompany: action.payload,
    isLoading: false,
  }
}

function handleGetAllCompanies(
  state: CompanyState,
  action: PayloadAction<ICompany[]>,
): CompanyState {
  return {
    ...state,
    allCompanies: action.payload,
    isLoading: false,
  }
}

export const companyReducer: Reducer<CompanyState, CompanyActionsType> =
  createReducer(COMPANY_INITIAL_STATE, {
    [getCompanyByIdThunk.pending.type]: handlePendingThunk,
    [getCompanyByIdThunk.rejected.type]: handleRejectedThunk,
    [getCompanyByIdThunk.fulfilled.type]: handleGetCompanyById,
    [getCompanyDashboardByIdThunk.pending.type]: handlePendingThunk,
    [getCompanyDashboardByIdThunk.rejected.type]: handleRejectedThunk,
    [getCompanyDashboardByIdThunk.fulfilled.type]:
      handleGetCompanyDashboardById,
    [getAllCompaniesThunk.pending.type]: handlePendingThunk,
    [getAllCompaniesThunk.rejected.type]: handleRejectedThunk,
    [getAllCompaniesThunk.fulfilled.type]: handleGetAllCompanies,
  })
