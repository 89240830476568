import styled, { css } from 'styled-components'

export const TalentWrapper = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  gap: 30px;
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`

export const GeneralInfo = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    & > h3 {
      color: ${theme.colors.hex.primary.dark};
      margin: 10px 0;
    }

    & > p {
      color: ${theme.colors.hex.secondary};
      margin: 10px 0;
    }
  `}
`

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
`

export const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  gap: 10px;
`

export const ChartSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 20px;

  div {
    width: calc(50% - 20px);
    height: 400px;
  }
`
