import React, { useState } from 'react'
import { Input } from '../../components/Input'
import { useAuth } from '../../hooks/useAuth'

import BG from '../../assets/bg-login.png'
import EitreeLogoIcon from '../../assets/logo-icon.png'

import * as LoginStyle from './style'
import { AuthProvider } from 'firebase/auth'
import { Loading } from '../../components/Loading'
import { Button } from '../../components/Button'

export const Login: React.FC = () => {
  const {
    email,
    password,
    confirmPassword,
    setEmail,
    setPassword,
    setConfirmPassword,
    loading,
    actions: { handleSubmit, handleSSO },
  } = useAuth()

  const [formType, setFormType] = useState('signIn')

  return (
    <LoginStyle.Wrapper>
      <LoginStyle.ImageWrapper>
        <LoginStyle.Background src={BG} alt="Eitree" />
        <LoginStyle.Logo src={EitreeLogoIcon} alt="Eitree" height={100} />
      </LoginStyle.ImageWrapper>
      <LoginStyle.Form
        onSubmit={event => {
          event.preventDefault()
          event.stopPropagation()
          handleSubmit(formType)
        }}>
        {formType === 'signIn' ? (
          <>
            <h2>Sign In</h2>
            <Input
              label="Email"
              type="email"
              onChange={event => setEmail(event.target.value)}
              value={email}
            />
            <Input
              label="Password"
              type="password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
            <button
              type={!loading ? 'submit' : 'button'}
              className={loading ? 'activated' : ''}>
              {!loading ? 'Sign In' : <Loading />}
            </button>

            <LoginStyle.Divider>or</LoginStyle.Divider>

            <Button type="button" onClick={() => setFormType('signUp')}>
              Sign Up
            </Button>

            <LoginStyle.SocialLoginWrapper>
              <LoginStyle.SocialLogin
                type="button"
                onClick={() => handleSSO('google' as unknown as AuthProvider)}>
                <img
                  src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/google/google-original.svg"
                  alt="Google"
                />
                SignIn with Google
              </LoginStyle.SocialLogin>
            </LoginStyle.SocialLoginWrapper>
          </>
        ) : (
          <>
            <h2>Sign Up</h2>
            <Input
              label="Email"
              type="email"
              onChange={event => setEmail(event.target.value)}
              value={email}
            />
            <Input
              label="Password"
              type="password"
              onChange={event => setPassword(event.target.value)}
              value={password}
            />
            <Input
              label="Confirm Password"
              type="password"
              onChange={event => setConfirmPassword(event.target.value)}
              value={confirmPassword}
              isValid={confirmPassword === password}
            />
            <button
              type={!loading ? 'submit' : 'button'}
              className={loading ? 'activated' : ''}>
              {!loading ? 'Sign Up' : <Loading />}
            </button>
            <LoginStyle.Divider>or</LoginStyle.Divider>
            <Button type="button" onClick={() => setFormType('signIn')}>
              Sign In
            </Button>
          </>
        )}
      </LoginStyle.Form>
    </LoginStyle.Wrapper>
  )
}
